import type { RouteTemplate } from "../RouteTemplate";
import { routeTemplate } from "../RouteTemplate";
import type { UnknownQueryParam } from "../query/QueryStringParam";
// N.B. These types are a work in progress. They will soon replace some of the other types we have building up routes and partial routes in the new routing infrastructure.
export function createRouteSegment<RouteParams, ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>>(routeSegment: RouteTemplate<RouteParams>, children: ChildRouteTreeNodes): MappedRouteSegment<RouteParams, ChildRouteTreeNodes> {
    const { pages, routeSegments } = splitPagesAndRouteSegments(children);
    const mappedPages: PrefixPagesWithParent<RouteParams, ExtractPages<ChildRouteTreeNodes>> = prefixPagesWithParent(routeSegment, pages);
    const mappedChildRoutes: PrefixChildRouteSegmentsWithParent<RouteParams, ExtractChildRouteSegments<ChildRouteTreeNodes>> = prefixRouteSegmentsWithParent(routeSegment, routeSegments);
    return {
        partialRoute: routeSegment,
        childRouteSegments: mappedChildRoutes,
        pages: mappedPages,
    };
}
// TODO: Rename this when we merge it with the existing createPageRoute function
export function createPageRouteNew<RouteParams, QueryParams extends UnknownQueryParam[]>(route: RouteTemplate<RouteParams>, queryParams: [
    ...QueryParams
]): PageRouteDef<RouteParams, QueryParams> {
    return {
        route,
        queryParams,
    };
}
export type RouteSegment<RouteParams, ChildRoutes extends RouteSegmentsConstraint, Pages extends PagesConstraint> = {
    partialRoute: RouteTemplate<RouteParams>;
    childRouteSegments: ChildRoutes;
    pages: Pages;
};
// TODO: Merge this with PageRouteDefinition
export interface PageRouteDef<RouteParams, QueryParams extends UnknownQueryParam[]> {
    route: RouteTemplate<RouteParams>;
    queryParams: QueryParams;
}
type MappedRouteSegment<RouteParams, ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>> = RouteSegment<RouteParams, PrefixChildRouteSegmentsWithParent<RouteParams, ExtractChildRouteSegments<ChildRouteTreeNodes>>, PrefixPagesWithParent<RouteParams, ExtractPages<ChildRouteTreeNodes>>>;
type ExtractChildRouteSegments<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>> = {
    [K in SegmentKeysOnly<ChildRouteTreeNodes>]: ChildRouteTreeNodes[K] extends RouteSegmentConstraint ? ChildRouteTreeNodes[K] : never;
};
type SegmentKeysOnly<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>> = {
    [K in keyof ChildRouteTreeNodes]: ChildRouteTreeNodes[K] extends RouteSegmentConstraint ? K : never;
}[keyof ChildRouteTreeNodes];
type ExtractPages<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>> = {
    [K in PageKeysOnly<ChildRouteTreeNodes>]: ChildRouteTreeNodes[K] extends PageConstraint ? ChildRouteTreeNodes[K] : never;
};
type PageKeysOnly<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>> = {
    [K in keyof ChildRouteTreeNodes]: ChildRouteTreeNodes[K] extends PageConstraint ? K : never;
}[keyof ChildRouteTreeNodes];
type PrefixChildRouteSegmentsWithParent<ParentRouteParams, ChildRouteSegments extends RouteSegmentsConstraint> = {
    [K in keyof ChildRouteSegments]: PrefixChildRouteSegmentWithParent<ParentRouteParams, ChildRouteSegments[K]>;
};
type PrefixChildRouteSegmentWithParent<ParentRouteParams, TRouteSegment extends RouteSegmentConstraint> = TRouteSegment extends RouteSegment<infer RouteSegmentParams, infer PartialRouteChildRoutes, infer Pages> ? RouteSegment<ParentRouteParams & RouteSegmentParams, PrefixChildRouteSegmentsWithParent<ParentRouteParams & RouteSegmentParams, PartialRouteChildRoutes>, PrefixPagesWithParent<ParentRouteParams & RouteSegmentParams, Pages>> : never;
type PrefixPagesWithParent<ParentRouteParams, Pages extends PagesConstraint> = {
    [K in keyof Pages]: PrefixPageWithParent<ParentRouteParams, Pages[K]>;
};
type PrefixPageWithParent<ParentRouteParams, Page extends PageConstraint> = Page extends PageRouteDef<infer RouteParams, infer QueryParams> ? PageRouteDef<ParentRouteParams & RouteParams, QueryParams> : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageConstraint = PageRouteDef<any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteSegmentConstraint = RouteSegment<any, any, any>;
type RouteSegmentsConstraint = Record<string, RouteSegmentConstraint>;
type PagesConstraint = Record<string, PageConstraint>;
function splitPagesAndRouteSegments<ChildRouteTreeNodes extends Record<string, PageConstraint | RouteSegmentConstraint>>(children: ChildRouteTreeNodes) {
    const pages = Object.entries(children)
        .filter(([key, node]) => isPage(node))
        .reduce<ExtractPages<ChildRouteTreeNodes>>((p, [key, node]) => ({ ...p, [key]: node }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ExtractPages<ChildRouteTreeNodes>);
    const routeSegments = Object.entries(children)
        .filter(([key, node]) => !isPage(node))
        .reduce<ExtractChildRouteSegments<ChildRouteTreeNodes>>((p, [key, node]) => ({ ...p, [key]: node }), 
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    {} as ExtractChildRouteSegments<ChildRouteTreeNodes>);
    return { pages, routeSegments };
}
function isPage(node: PageConstraint | RouteSegmentConstraint): node is PageConstraint {
    return "route" in node;
}
function prefixPagesWithParent<RouteParams, Pages extends PagesConstraint>(parentPrefix: RouteTemplate<RouteParams>, pages: Pages): PrefixPagesWithParent<RouteParams, Pages> {
    return Object.entries(pages).reduce<PrefixPagesWithParent<RouteParams, Pages>>((p, [key, page]) => {
        return {
            ...p,
            [key]: prefixPage(parentPrefix, page),
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as PrefixPagesWithParent<RouteParams, Pages>);
}
function prefixPage<ParentRouteParams, ChildRouteParams, QueryParams extends UnknownQueryParam[]>(parentPrefix: RouteTemplate<ParentRouteParams>, page: PageRouteDef<ChildRouteParams, QueryParams>): PageRouteDef<ParentRouteParams & ChildRouteParams, QueryParams> {
    return {
        route: routeTemplate `${parentPrefix}${page.route}`,
        queryParams: page.queryParams,
    };
}
function prefixRouteSegmentsWithParent<RouteParams, ChildRoutes extends RouteSegmentsConstraint>(parentPrefix: RouteTemplate<RouteParams>, childRoutes: ChildRoutes): PrefixChildRouteSegmentsWithParent<RouteParams, ChildRoutes> {
    return Object.entries(childRoutes).reduce<PrefixChildRouteSegmentsWithParent<RouteParams, ChildRoutes>>((p, [key, routeSegment]) => {
        return {
            ...p,
            [key]: prefixRouteSegment(parentPrefix, routeSegment),
        };
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    }, {} as PrefixChildRouteSegmentsWithParent<RouteParams, ChildRoutes>);
}
function prefixRouteSegment<ParentRouteParams, ChildRouteParams, ChildRouteSegments extends RouteSegmentsConstraint, Pages extends PagesConstraint>(prefix: RouteTemplate<ParentRouteParams>, routeSegment: RouteSegment<ChildRouteParams, ChildRouteSegments, Pages>): RouteSegment<ParentRouteParams & ChildRouteParams, PrefixChildRouteSegmentsWithParent<ParentRouteParams, ChildRouteSegments>, PrefixPagesWithParent<ParentRouteParams, Pages>> {
    const { partialRoute, childRouteSegments, pages } = routeSegment;
    return {
        partialRoute: routeTemplate `${prefix}${partialRoute}`,
        childRouteSegments: prefixRouteSegmentsWithParent(prefix, childRouteSegments),
        pages: prefixPagesWithParent(prefix, pages),
    };
}
