import type { Expand } from "@octopusdeploy/type-utils";
import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import URI from "urijs";
export interface QueryParam<TKey extends string, TValue> {
    name: TKey;
    parse: (serializedValue: string) => TValue | ParseError;
    serialize: (value: TValue) => string;
}
// This type is used for type constraints and cannot be "unknown"
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownQueryParam = QueryParam<string, any>;
export const parseError = Symbol();
export type ParseError = typeof parseError;
export function createQueryParam<TKey extends string, TValue>(paramName: TKey, parse: (serializedValue: string) => TValue | ParseError, serialize: (value: TValue) => string): QueryParam<TKey, TValue> {
    return {
        name: paramName,
        parse,
        serialize,
    };
}
export function stringQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, string> {
    return createQueryParam(paramName, (serializedValue) => serializedValue, (value) => value);
}
export function numberQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, number> {
    return createQueryParam(paramName, (serializedValue) => {
        const parsedValue = parseInt(serializedValue);
        if (Number.isNaN(parsedValue))
            return parseError;
        return parsedValue;
    }, (value) => value.toString());
}
export function booleanQueryParam<TKey extends string>(paramName: TKey): QueryParam<TKey, boolean> {
    return createQueryParam(paramName, (serializedValue) => (serializedValue === "true" ? true : serializedValue === "false" ? false : parseError), (value) => value.toString());
}
export type ParsedQueryParams<QueryParams> = QueryParams extends UnknownQueryParam[] ? ParseQueryParamsArray<QueryParams> : never;
type ParseQueryParamsArray<QueryParams extends UnknownQueryParam[]> = Expand<MergeObjects<{
    [K in keyof QueryParams]: MapQueryParamToObject<QueryParams[K]>;
}>>;
type MapQueryParamToObject<Param extends UnknownQueryParam> = Param extends QueryParam<infer TKey extends string, infer TValue> ? QueryStringProperties<TKey, TValue> : never;
type QueryStringProperties<TKey extends string, TValue> = {
    [N in TKey]?: TValue;
};
type MergeObjects<T extends unknown[]> = T extends [
    infer Value
] ? Value : T extends [
    infer Value,
    ...infer Rest
] ? Value & MergeObjects<Rest> : {};
type NextQueryParamValue<T> = T | ((prevValue: T) => T);
type SetQueryParamValue<T> = (value: NextQueryParamValue<T>) => void;
export enum QueryStateMode {
    PushHistory = "PushHistory",
    ReplaceHistory = "ReplaceHistory"
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useQueryStringParams<QueryStringParams extends QueryParam<string, any>[]>(paramDefinitions: [
    ...QueryStringParams
], mode: QueryStateMode = QueryStateMode.PushHistory): [
    ParsedQueryParams<QueryStringParams>,
    SetQueryParamValue<ParsedQueryParams<QueryStringParams>>
] {
    const updateUrl = useUpdateUrl();
    const { search, pathname } = useLocation();
    const values = useMemo(() => {
        const params = new URLSearchParams(search);
        const values = paramDefinitions.reduce<Partial<ParsedQueryParams<QueryStringParams>>>((prev, paramDefinition) => {
            const name = paramDefinition.name;
            const paramStringValue = params.get(name);
            if (paramStringValue !== null) {
                const parsedValue = paramDefinition.parse(paramStringValue);
                if (parsedValue !== parseError) {
                    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                    prev[name as keyof ParsedQueryParams<QueryStringParams>] = parsedValue;
                }
            }
            return prev;
        }, {});
        // We know this is a complete (non-partial) object at this point
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return values as ParsedQueryParams<QueryStringParams>;
    }, [paramDefinitions, search]);
    const updateQueryParamValue = useCallback((nextQueryParamValue: NextQueryParamValue<ParsedQueryParams<QueryStringParams>>) => {
        const url = new URI(pathname + search);
        const newParamValue = isQueryParamValueFunction(nextQueryParamValue) ? nextQueryParamValue(values) : nextQueryParamValue;
        paramDefinitions.forEach((paramDefinition) => {
            // We know that this param definition name is assignable
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const name: keyof ParsedQueryParams<QueryStringParams> = paramDefinition.name;
            if (newParamValue[name] !== undefined) {
                const newValue = newParamValue[name];
                const serializedValue = paramDefinition.serialize(newValue);
                url.setQuery(name, serializedValue);
            }
            else {
                url.removeQuery(name);
            }
        });
        updateUrl(url, mode);
    }, [paramDefinitions, pathname, updateUrl, search]);
    return [values, updateQueryParamValue];
}
export function useQueryStringParam<TKey extends string, TValue>(paramDefinition: QueryParam<TKey, TValue>, mode: QueryStateMode = QueryStateMode.PushHistory): [
    TValue | undefined,
    SetQueryParamValue<TValue | undefined>
] {
    const updateUrl = useUpdateUrl();
    const { search, pathname } = useLocation();
    const params = new URLSearchParams(search);
    const serializedValue = params.get(paramDefinition.name);
    const parsedValue = serializedValue !== null ? paramDefinition.parse(serializedValue) : undefined;
    const value = parsedValue === parseError ? undefined : parsedValue;
    const updateQueryParamValue = useCallback((nextQueryParamValue: NextQueryParamValue<TValue | undefined>) => {
        const url = new URI(pathname + search);
        const newParamValue = isQueryParamValueFunction(nextQueryParamValue) ? nextQueryParamValue(value) : nextQueryParamValue;
        if (newParamValue === undefined) {
            url.removeQuery(paramDefinition.name);
            updateUrl(url, mode);
        }
        else {
            const newSerializedValue = paramDefinition.serialize(newParamValue);
            if (newSerializedValue !== serializedValue) {
                url.setQuery(paramDefinition.name, newSerializedValue);
                updateUrl(url, mode);
            }
        }
    }, [mode, paramDefinition, pathname, updateUrl, search, serializedValue]);
    return [value, updateQueryParamValue];
}
function isQueryParamValueFunction<T>(value: NextQueryParamValue<T>): value is (t: T) => T {
    return typeof value === "function";
}
function useUpdateUrl() {
    const { push, replace } = useHistory();
    return (url: URI, mode: QueryStateMode = QueryStateMode.PushHistory) => {
        const newUrl = url.toString();
        if (mode === QueryStateMode.PushHistory) {
            push(newUrl);
        }
        else {
            replace(newUrl);
        }
    };
}
