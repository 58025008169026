import { createPageRoute } from "./PageRouteDefinition";
import { createPartialRoute } from "./PartialRouteDefinition";
import { routeParam, routeTemplate } from "./RouteTemplate";
import { stringQueryParam } from "./query/QueryStringParam";
import { spacePartialRoute } from "./spaceRoutes";
export const projectsPartialRoute = createPartialRoute(routeTemplate `${spacePartialRoute.template}/projects`);
export const projectsImportExportPartialRoute = createPartialRoute(routeTemplate `${spacePartialRoute.template}/projects/importExport`);
export const projectPartialRoute = createPartialRoute(routeTemplate `${projectsPartialRoute.template}/${routeParam("projectSlug")}`);
export const projectBranchPartialRoute = createPartialRoute(routeTemplate `${projectPartialRoute.template}/branches/${routeParam("branchName")}`);
export const projectBranchDeploymentPartialRoute = createPartialRoute(routeTemplate `${projectBranchPartialRoute.template}/deployments`);
export const projectBranchDeploymentProcessRoute = createPageRoute(routeTemplate `/process`, projectBranchDeploymentPartialRoute, []);
export const projectBranchDeploymentProcessStepsRoute = createPageRoute(routeTemplate `/process/steps`, projectBranchDeploymentPartialRoute, []);
export const projectBranchDeploymentSettingsRoute = createPageRoute(routeTemplate `/settings`, projectBranchDeploymentPartialRoute, []);
export const projectBranchVariablesPartialRoute = createPartialRoute(routeTemplate `${projectBranchPartialRoute.template}/variables`);
export const projectBranchVariablesRoute = createPageRoute(routeTemplate ``, projectBranchVariablesPartialRoute, []);
export const projectBranchAllVariablesRoute = createPageRoute(routeTemplate `/all`, projectBranchVariablesPartialRoute, []);
export const projectBranchVariablesPreviewRoute = createPageRoute(routeTemplate `/preview`, projectBranchVariablesPartialRoute, []);
export const projectDeploymentPartialRoute = createPartialRoute(routeTemplate `${projectPartialRoute.template}/deployments`);
export const projectDeploymentOverviewRoute = createPageRoute(routeTemplate ``, projectDeploymentPartialRoute, []);
export const projectDeploymentProcessPartialRoute = createPartialRoute(routeTemplate `${projectDeploymentPartialRoute.template}/process`);
export const projectDeploymentProcessRoute = createPageRoute(routeTemplate ``, projectDeploymentProcessPartialRoute, []);
export const projectDeploymentProcessStepsRoute = createPageRoute(routeTemplate `/steps`, projectDeploymentProcessPartialRoute, []);
export const projectDeploymentSettingsPartialRoute = createPartialRoute(routeTemplate `${projectDeploymentPartialRoute.template}/settings`);
export const projectDeploymentSettingsRoute = createPageRoute(routeTemplate ``, projectDeploymentSettingsPartialRoute, []);
export const projectDeploymentChannelsRoute = createPageRoute(routeTemplate `/channels`, projectDeploymentPartialRoute, []);
export const projectDeploymentChannelsCreateRoute = createPageRoute(routeTemplate `/channels/create`, projectDeploymentPartialRoute, []);
export const projectDeploymentChannelsEditRoute = createPageRoute(routeTemplate `/channels/edit/${routeParam("channelId")}`, projectDeploymentPartialRoute, []);
export const projectVariablesPartialRoute = createPartialRoute(routeTemplate `${projectPartialRoute.template}/variables`);
// Here we create an additional partial route at the same location as the project variables partial route.
// This is because we want to wrap the projectVariablesRoute with a branch redirect component.
// However we don't want this to apply to all sub routes of the variables partial route, (e.g. the variables library route is not version controlled so should not redirect).
// So this additional partial route layer allows us to isolate this redirect behaviour to just the routes that need it.
export const projectVariablesPartialRouteWrapper = createPartialRoute(routeTemplate `${projectVariablesPartialRoute.template}`);
export const projectVariablesRoute = createPageRoute(routeTemplate ``, projectVariablesPartialRouteWrapper, []);
export const projectAllVariablesPartialRoute = createPartialRoute(routeTemplate `${projectVariablesPartialRoute.template}/all`);
export const projectAllVariablesRoute = createPageRoute(routeTemplate ``, projectAllVariablesPartialRoute, []);
export const projectVariablesPreviewPartialRoute = createPartialRoute(routeTemplate `${projectVariablesPartialRoute.template}/preview`);
export const projectVariablesPreviewRoute = createPageRoute(routeTemplate ``, projectVariablesPreviewPartialRoute, []);
export const projectVariablesLibraryRoute = createPageRoute(routeTemplate `/library`, projectVariablesPartialRoute, []);
export const projectVariableTemplatesRoute = createPageRoute(routeTemplate `/projectvariabletemplates`, projectVariablesPartialRoute, []);
export const projectCommonVariableTemplatesRoute = createPageRoute(routeTemplate `/commonvariabletemplates`, projectVariablesPartialRoute, []);
export const projectsRoute = createPageRoute(routeTemplate ``, projectsPartialRoute, [stringQueryParam("projectGroupId"), stringQueryParam("projectName"), stringQueryParam("newProjectGroupId")]);
export const projectTasksRoute = createPageRoute(routeTemplate `/tasks`, projectPartialRoute, []);
