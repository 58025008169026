export const color = {
    "alert": {
        "text": {
            "info": "var(--colorAlertTextInfo)",
            "success": "var(--colorAlertTextSuccess)",
            "warning": "var(--colorAlertTextWarning)",
            "danger": "var(--colorAlertTextDanger)",
            "body": "var(--colorAlertTextBody)"
        },
        "icon": {
            "info": "var(--colorAlertIconInfo)",
            "success": "var(--colorAlertIconSuccess)",
            "warning": "var(--colorAlertIconWarning)",
            "danger": "var(--colorAlertIconDanger)",
            "dismiss": "var(--colorAlertIconDismiss)"
        },
        "border": {
            "info": "var(--colorAlertBorderInfo)",
            "success": "var(--colorAlertBorderSuccess)",
            "warning": "var(--colorAlertBorderWarning)",
            "danger": "var(--colorAlertBorderDanger)"
        },
        "background": {
            "info": "var(--colorAlertBackgroundInfo)",
            "success": "var(--colorAlertBackgroundSuccess)",
            "warning": "var(--colorAlertBackgroundWarning)",
            "danger": "var(--colorAlertBackgroundDanger)"
        }
    },
    "menuList": {
        "text": {
            "primary": "var(--colorMenuListTextPrimary)",
            "secondary": "var(--colorMenuListTextSecondary)",
            "highlight": "var(--colorMenuListTextHighlight)"
        },
        "background": {
            "default": "var(--colorMenuListBackgroundDefault)",
            "hover": "var(--colorMenuListBackgroundHover)"
        },
        "icon": {
            "primary": "var(--colorMenuListIconPrimary)"
        }
    },
    "textField": {
        "text": {
            "primary": "var(--colorTextFieldTextPrimary)",
            "muted": "var(--colorTextFieldTextMuted)",
            "selected": "var(--colorTextFieldTextSelected)",
            "danger": "var(--colorTextFieldTextDanger)",
            "disabled": "var(--colorTextFieldTextDisabled)"
        },
        "border": {
            "default": "var(--colorTextFieldBorderDefault)",
            "hover": "var(--colorTextFieldBorderHover)",
            "active": "var(--colorTextFieldBorderActive)",
            "danger": "var(--colorTextFieldBorderDanger)",
            "disabled": "var(--colorTextFieldBorderDisabled)"
        },
        "icon": {
            "primary": "var(--colorTextFieldIconPrimary)",
            "disabled": "var(--colorTextFieldIconDisabled)"
        }
    },
    "chip": {
        "filled": {
            "text": {
                "primary": "var(--colorChipFilledTextPrimary)",
                "secondary": "var(--colorChipFilledTextSecondary)",
                "info": "var(--colorChipFilledTextInfo)",
                "success": "var(--colorChipFilledTextSuccess)",
                "warning": "var(--colorChipFilledTextWarning)",
                "danger": "var(--colorChipFilledTextDanger)"
            },
            "background": {
                "primary": "var(--colorChipFilledBackgroundPrimary)",
                "secondary": "var(--colorChipFilledBackgroundSecondary)",
                "info": "var(--colorChipFilledBackgroundInfo)",
                "success": "var(--colorChipFilledBackgroundSuccess)",
                "warning": "var(--colorChipFilledBackgroundWarning)",
                "danger": "var(--colorChipFilledBackgroundDanger)"
            },
            "icon": {
                "primary": "var(--colorChipFilledIconPrimary)",
                "secondary": "var(--colorChipFilledIconSecondary)",
                "info": "var(--colorChipFilledIconInfo)",
                "success": "var(--colorChipFilledIconSuccess)",
                "warning": "var(--colorChipFilledIconWarning)",
                "danger": "var(--colorChipFilledIconDanger)"
            }
        },
        "outlined": {
            "border": {
                "primary": "var(--colorChipOutlinedBorderPrimary)",
                "secondary": "var(--colorChipOutlinedBorderSecondary)",
                "info": "var(--colorChipOutlinedBorderInfo)",
                "success": "var(--colorChipOutlinedBorderSuccess)",
                "warning": "var(--colorChipOutlinedBorderWarning)",
                "danger": "var(--colorChipOutlinedBorderDanger)"
            },
            "text": {
                "primary": "var(--colorChipOutlinedTextPrimary)",
                "secondary": "var(--colorChipOutlinedTextSecondary)",
                "info": "var(--colorChipOutlinedTextInfo)",
                "success": "var(--colorChipOutlinedTextSuccess)",
                "warning": "var(--colorChipOutlinedTextWarning)",
                "danger": "var(--colorChipOutlinedTextDanger)"
            },
            "icon": {
                "primary": "var(--colorChipOutlinedIconPrimary)",
                "secondary": "var(--colorChipOutlinedIconSecondary)",
                "info": "var(--colorChipOutlinedIconInfo)",
                "success": "var(--colorChipOutlinedIconSuccess)",
                "warning": "var(--colorChipOutlinedIconWarning)",
                "danger": "var(--colorChipOutlinedIconDanger)"
            }
        }
    },
    "button": {
        "background": {
            "primary": {
                "default": "var(--colorButtonBackgroundPrimaryDefault)",
                "hover": "var(--colorButtonBackgroundPrimaryHover)",
                "pressed": "var(--colorButtonBackgroundPrimaryPressed)",
                "disabled": "var(--colorButtonBackgroundPrimaryDisabled)"
            },
            "secondary": {
                "default": "var(--colorButtonBackgroundSecondaryDefault)",
                "hover": "var(--colorButtonBackgroundSecondaryHover)",
                "pressed": "var(--colorButtonBackgroundSecondaryPressed)",
                "disabled": "var(--colorButtonBackgroundSecondaryDisabled)"
            },
            "tertiary": {
                "default": "var(--colorButtonBackgroundTertiaryDefault)",
                "hover": "var(--colorButtonBackgroundTertiaryHover)",
                "pressed": "var(--colorButtonBackgroundTertiaryPressed)",
                "disabled": "var(--colorButtonBackgroundTertiaryDisabled)"
            },
            "destructive": {
                "default": "var(--colorButtonBackgroundDestructiveDefault)",
                "hover": "var(--colorButtonBackgroundDestructiveHover)",
                "pressed": "var(--colorButtonBackgroundDestructivePressed)",
                "disabled": "var(--colorButtonBackgroundDestructiveDisabled)"
            },
            "quiet": {
                "default": "var(--colorButtonBackgroundQuietDefault)",
                "hover": "var(--colorButtonBackgroundQuietHover)",
                "pressed": "var(--colorButtonBackgroundQuietPressed)",
                "disabled": "var(--colorButtonBackgroundQuietDisabled)"
            },
            "loud": {
                "default": "var(--colorButtonBackgroundLoudDefault)",
                "hover": "var(--colorButtonBackgroundLoudHover)",
                "pressed": "var(--colorButtonBackgroundLoudPressed)",
                "disabled": "var(--colorButtonBackgroundLoudDisabled)"
            },
            "neutral": {
                "default": "var(--colorButtonBackgroundNeutralDefault)",
                "hover": "var(--colorButtonBackgroundNeutralHover)",
                "pressed": "var(--colorButtonBackgroundNeutralPressed)",
                "disabled": "var(--colorButtonBackgroundNeutralDisabled)"
            }
        },
        "icon": {
            "primary": {
                "default": "var(--colorButtonIconPrimaryDefault)",
                "disabled": "var(--colorButtonIconPrimaryDisabled)"
            },
            "secondary": {
                "default": "var(--colorButtonIconSecondaryDefault)",
                "hover": "var(--colorButtonIconSecondaryHover)",
                "disabled": "var(--colorButtonIconSecondaryDisabled)"
            },
            "tertiary": {
                "default": "var(--colorButtonIconTertiaryDefault)",
                "disabled": "var(--colorButtonIconTertiaryDisabled)"
            },
            "destructive": {
                "default": "var(--colorButtonIconDestructiveDefault)",
                "disabled": "var(--colorButtonIconDestructiveDisabled)"
            },
            "quiet": {
                "default": "var(--colorButtonIconQuietDefault)",
                "hover": "var(--colorButtonIconQuietHover)",
                "disabled": "var(--colorButtonIconQuietDisabled)"
            },
            "loud": {
                "default": "var(--colorButtonIconLoudDefault)",
                "disabled": "var(--colorButtonIconLoudDisabled)"
            },
            "neutral": {
                "default": "var(--colorButtonIconNeutralDefault)",
                "hover": "var(--colorButtonIconNeutralHover)",
                "disabled": "var(--colorButtonIconNeutralDisabled)"
            }
        },
        "text": {
            "primary": {
                "default": "var(--colorButtonTextPrimaryDefault)",
                "disabled": "var(--colorButtonTextPrimaryDisabled)"
            },
            "secondary": {
                "default": "var(--colorButtonTextSecondaryDefault)",
                "disabled": "var(--colorButtonTextSecondaryDisabled)"
            },
            "tertiary": {
                "default": "var(--colorButtonTextTertiaryDefault)",
                "disabled": "var(--colorButtonTextTertiaryDisabled)"
            },
            "destructive": {
                "default": "var(--colorButtonTextDestructiveDefault)",
                "disabled": "var(--colorButtonTextDestructiveDisabled)"
            },
            "quiet": {
                "default": "var(--colorButtonTextQuietDefault)",
                "hover": "var(--colorButtonTextQuietHover)",
                "disabled": "var(--colorButtonTextQuietDisabled)"
            },
            "loud": {
                "default": "var(--colorButtonTextLoudDefault)",
                "disabled": "var(--colorButtonTextLoudDisabled)"
            },
            "neutral": {
                "default": "var(--colorButtonTextNeutralDefault)",
                "hover": "var(--colorButtonTextNeutralHover)",
                "disabled": "var(--colorButtonTextNeutralDisabled)"
            }
        },
        "border": {
            "secondary": {
                "default": "var(--colorButtonBorderSecondaryDefault)",
                "disabled": "var(--colorButtonBorderSecondaryDisabled)"
            },
            "tertiary": {
                "default": "var(--colorButtonBorderTertiaryDefault)",
                "disabled": "var(--colorButtonBorderTertiaryDisabled)"
            }
        }
    },
    "dialog": {
        "background": {
            "primary": "var(--colorDialogBackgroundPrimary)"
        }
    },
    "progress": {
        "background": {
            "primary": "var(--colorProgressBackgroundPrimary)",
            "secondary": "var(--colorProgressBackgroundSecondary)"
        },
        "text": {
            "info": "var(--colorProgressTextInfo)"
        }
    },
    "progressBar": {
        "foreground": {
            "linear": "var(--colorProgressBarForegroundLinear)",
            "circular": "var(--colorProgressBarForegroundCircular)"
        },
        "background": {
            "linear": "var(--colorProgressBarBackgroundLinear)"
        },
        "text": {
            "primary": "var(--colorProgressBarTextPrimary)"
        }
    },
    "navigation": {
        "button": {
            "background": {
                "primary": {
                    "default": "var(--colorNavigationButtonBackgroundPrimaryDefault)",
                    "hover": "var(--colorNavigationButtonBackgroundPrimaryHover)",
                    "pressed": "var(--colorNavigationButtonBackgroundPrimaryPressed)"
                }
            },
            "text": {
                "primary": "var(--colorNavigationButtonTextPrimary)"
            },
            "icon": {
                "primary": "var(--colorNavigationButtonIconPrimary)"
            }
        },
        "background": {
            "primary": "var(--colorNavigationBackgroundPrimary)",
            "secondary": "var(--colorNavigationBackgroundSecondary)"
        },
        "link": {
            "background": {
                "primary": {
                    "hover": "var(--colorNavigationLinkBackgroundPrimaryHover)",
                    "pressed": "var(--colorNavigationLinkBackgroundPrimaryPressed)",
                    "active": "var(--colorNavigationLinkBackgroundPrimaryActive)"
                }
            },
            "text": {
                "primary": "var(--colorNavigationLinkTextPrimary)"
            },
            "icon": {
                "primary": "var(--colorNavigationLinkIconPrimary)"
            }
        }
    },
    "pagination": {
        "background": {
            "default": "var(--colorPaginationBackgroundDefault)",
            "hover": "var(--colorPaginationBackgroundHover)",
            "active": "var(--colorPaginationBackgroundActive)",
            "disabled": "var(--colorPaginationBackgroundDisabled)"
        },
        "text": {
            "default": "var(--colorPaginationTextDefault)",
            "hover": "var(--colorPaginationTextHover)",
            "active": "var(--colorPaginationTextActive)",
            "disabled": "var(--colorPaginationTextDisabled)"
        },
        "icon": {
            "default": "var(--colorPaginationIconDefault)",
            "hover": "var(--colorPaginationIconHover)",
            "active": "var(--colorPaginationIconActive)",
            "disabled": "var(--colorPaginationIconDisabled)"
        }
    },
    "toggle": {
        "icon": {
            "default": "var(--colorToggleIconDefault)",
            "active": "var(--colorToggleIconActive)",
            "disabled": "var(--colorToggleIconDisabled)"
        },
        "background": {
            "on": "var(--colorToggleBackgroundOn)",
            "off": "var(--colorToggleBackgroundOff)",
            "disabled": "var(--colorToggleBackgroundDisabled)"
        },
        "foreground": {
            "on": "var(--colorToggleForegroundOn)",
            "off": "var(--colorToggleForegroundOff)",
            "disabled": "var(--colorToggleForegroundDisabled)"
        },
        "overlay": {
            "on": "var(--colorToggleOverlayOn)",
            "off": "var(--colorToggleOverlayOff)"
        }
    },
    "text": {
        "primary": "var(--colorTextPrimary)",
        "muted": "var(--colorTextMuted)",
        "subtle": "var(--colorTextSubtle)",
        "disabled": "var(--colorTextDisabled)",
        "inverse": "var(--colorTextInverse)",
        "selected": "var(--colorTextSelected)",
        "info": "var(--colorTextInfo)",
        "success": "var(--colorTextSuccess)",
        "warning": "var(--colorTextWarning)",
        "danger": "var(--colorTextDanger)",
        "feature": "var(--colorTextFeature)",
        "link": {
            "default": "var(--colorTextLinkDefault)",
            "pressed": "var(--colorTextLinkPressed)"
        }
    },
    "icon": {
        "primary": "var(--colorIconPrimary)",
        "muted": "var(--colorIconMuted)",
        "subtle": "var(--colorIconSubtle)",
        "disabled": "var(--colorIconDisabled)",
        "inverse": "var(--colorIconInverse)",
        "selected": "var(--colorIconSelected)",
        "info": "var(--colorIconInfo)",
        "success": "var(--colorIconSuccess)",
        "warning": "var(--colorIconWarning)",
        "danger": "var(--colorIconDanger)",
        "feature": "var(--colorIconFeature)",
        "link": {
            "default": "var(--colorIconLinkDefault)",
            "pressed": "var(--colorIconLinkPressed)"
        }
    },
    "border": {
        "primary": "var(--colorBorderPrimary)",
        "muted": "var(--colorBorderMuted)",
        "subtle": "var(--colorBorderSubtle)",
        "disabled": "var(--colorBorderDisabled)",
        "inverse": "var(--colorBorderInverse)",
        "selected": "var(--colorBorderSelected)",
        "info": "var(--colorBorderInfo)",
        "success": "var(--colorBorderSuccess)",
        "warning": "var(--colorBorderWarning)",
        "danger": "var(--colorBorderDanger)",
        "feature": "var(--colorBorderFeature)",
        "input": "var(--colorBorderInput)",
        "link": {
            "default": "var(--colorBorderLinkDefault)",
            "pressed": "var(--colorBorderLinkPressed)"
        }
    },
    "background": {
        "primary": {
            "default": "var(--colorBackgroundPrimaryDefault)",
            "hovered": "var(--colorBackgroundPrimaryHovered)",
            "pressed": "var(--colorBackgroundPrimaryPressed)"
        },
        "secondary": {
            "default": "var(--colorBackgroundSecondaryDefault)",
            "hovered": "var(--colorBackgroundSecondaryHovered)",
            "pressed": "var(--colorBackgroundSecondaryPressed)"
        },
        "subtle": "var(--colorBackgroundSubtle)",
        "disabled": "var(--colorBackgroundDisabled)",
        "contrast": "var(--colorBackgroundContrast)",
        "inverse": {
            "primary": "var(--colorBackgroundInversePrimary)",
            "secondary": "var(--colorBackgroundInverseSecondary)",
            "neutral": "var(--colorBackgroundInverseNeutral)",
            "success": "var(--colorBackgroundInverseSuccess)"
        },
        "selected": "var(--colorBackgroundSelected)",
        "info": "var(--colorBackgroundInfo)",
        "success": "var(--colorBackgroundSuccess)",
        "warning": "var(--colorBackgroundWarning)",
        "danger": "var(--colorBackgroundDanger)",
        "feature": "var(--colorBackgroundFeature)"
    },
    "overlay": {
        "background": {
            "primary": "var(--colorOverlayBackgroundPrimary)",
            "muted": "var(--colorOverlayBackgroundMuted)"
        }
    },
    "actionButton": {
        "border": {
            "secondary": "var(--colorActionButtonBorderSecondary)",
            "disabled": "var(--colorActionButtonBorderDisabled)"
        },
        "text": {
            "primary": "var(--colorActionButtonTextPrimary)",
            "secondary": "var(--colorActionButtonTextSecondary)",
            "ternary": "var(--colorActionButtonTextTernary)",
            "disabled": "var(--colorActionButtonTextDisabled)"
        },
        "icon": {
            "primary": "var(--colorActionButtonIconPrimary)",
            "secondary": "var(--colorActionButtonIconSecondary)",
            "ternary": "var(--colorActionButtonIconTernary)",
            "disabled": "var(--colorActionButtonIconDisabled)"
        },
        "background": {
            "disabled": "var(--colorActionButtonBackgroundDisabled)",
            "primary": {
                "default": "var(--colorActionButtonBackgroundPrimaryDefault)",
                "hover": "var(--colorActionButtonBackgroundPrimaryHover)",
                "pressed": "var(--colorActionButtonBackgroundPrimaryPressed)"
            },
            "secondary": {
                "default": "var(--colorActionButtonBackgroundSecondaryDefault)",
                "hover": "var(--colorActionButtonBackgroundSecondaryHover)",
                "pressed": "var(--colorActionButtonBackgroundSecondaryPressed)"
            },
            "ternary": {
                "default": "var(--colorActionButtonBackgroundTernaryDefault)",
                "hover": "var(--colorActionButtonBackgroundTernaryHover)",
                "pressed": "var(--colorActionButtonBackgroundTernaryPressed)"
            },
            "createRelease": {
                "default": "var(--colorActionButtonBackgroundCreateReleaseDefault)",
                "hover": "var(--colorActionButtonBackgroundCreateReleaseHover)",
                "pressed": "var(--colorActionButtonBackgroundCreateReleasePressed)"
            }
        }
    },
    "card": {
        "background": {
            "default": "var(--colorCardBackgroundDefault)",
            "hover": "var(--colorCardBackgroundHover)"
        }
    },
    "carousel": {
        "text": {
            "primary": "var(--colorCarouselTextPrimary)",
            "muted": "var(--colorCarouselTextMuted)"
        },
        "icon": {
            "default": "var(--colorCarouselIconDefault)",
            "hover": "var(--colorCarouselIconHover)",
            "selected": "var(--colorCarouselIconSelected)",
            "active": "var(--colorCarouselIconActive)"
        },
        "background": {
            "primary": "var(--colorCarouselBackgroundPrimary)"
        }
    }
} as const;
export const shadow = {
    "extraSmall": "var(--shadowExtraSmall)",
    "small": "var(--shadowSmall)",
    "medium": "var(--shadowMedium)",
    "large": "var(--shadowLarge)",
    "focus": {
        "default": "var(--shadowFocusDefault)"
    }
} as const;
