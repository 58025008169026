import { createPageRoute } from "./PageRouteDefinition";
import { createPartialRoute } from "./PartialRouteDefinition";
import { routeTemplate } from "./RouteTemplate";
import type { RouteTemplate } from "./RouteTemplate";
import type { UnknownQueryParam } from "./query/QueryStringParam";
import { systemPartialRoute } from "./systemRoutes";
export const configurationPartialRoute = createPartialRoute(routeTemplate `${systemPartialRoute.template}/configuration`);
function createConfigurationRoute<RouteParams, QueryParams extends UnknownQueryParam[]>(template: RouteTemplate<RouteParams>, queryParams: [
    ...QueryParams
]) {
    return createPageRoute(template, configurationPartialRoute, queryParams);
}
export const licenseRoute = createConfigurationRoute(routeTemplate `/license`, []);
export const gitRoute = createConfigurationRoute(routeTemplate `/git`, []);
