/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { typeSafeHasOwnProperty } from "../utils";
import type { PhaseProgress } from "./lifecycleProgressionResource";
import type { ReleasePackageVersionBuildInformationResource } from "./packageResource";
import type { IPhasedResource } from "./phaseResource";
import type { ResourceWithId } from "./resource";
import type { RunbookSnapshotResource } from "./runbookSnapshotResource";
import type { SpaceScopedResource } from "./spaceScopedResource";
import type { TaskState } from "./taskState";
import type { GitReference, IHaveSnapshotGitReference } from "./versionControlledResource";
export interface SelectedPackage {
    ActionName: string;
    PackageReferenceName?: string;
    Version: string;
}
export interface SelectedGitResource {
    ActionName: string;
    GitReferenceResource: GitReference;
}
// TODO: Add concrete links
export interface ReleaseResource extends ISnapshotResource, SpaceScopedResource, ResourceWithId {
    ChannelId: string;
    Version: string;
    ReleaseNotes: string;
    IgnoreChannelRules: boolean;
    ProjectDeploymentProcessSnapshotId: string;
    BuildInformation: ReleasePackageVersionBuildInformationResource[];
}
//TODO: @team-fire-and-motion - Remove this resource once we update the release data model and no longer require this optimization
export interface OverviewReleaseBff extends SpaceScopedResource, ResourceWithId {
    ChannelId: string;
    Version: string;
    ProjectId: string;
    VersionControlReference?: GitReference;
}
export interface ISnapshotResource extends ResourceWithId, IHaveSnapshotGitReference {
    Assembled: string;
    ProjectId: string;
    ProjectVariableSetSnapshotId: string;
    LibraryVariableSetSnapshotIds: string[];
    SelectedPackages: SelectedPackage[];
    SelectedGitResources: SelectedGitResource[];
}
export function isReleaseResource(resource: ISnapshotResource): resource is NonNullable<ReleaseResource> {
    if (resource === undefined || resource === null) {
        return false;
    }
    const converted = resource as ReleaseResource;
    return converted.Version !== undefined && typeSafeHasOwnProperty(converted, "Version");
}
export function isRunbookSnapshotResource(resource: ISnapshotResource): resource is NonNullable<RunbookSnapshotResource> {
    if (resource === undefined || resource === null) {
        return false;
    }
    const converted = resource as RunbookSnapshotResource;
    return converted.Name !== undefined && typeSafeHasOwnProperty(converted, "Name");
}
export interface GetReleaseDetailBffResponse {
    ReleaseProgression: GetReleaseDetailBffResponseProgression;
    Deployments: GetReleaseDeploymentBff[];
}
export interface GetReleaseDetailBffResponseProgression {
    Phases: GetReleaseProgressionPhaseBff[];
    NextDeployments: string[];
}
export interface GetReleaseProgressionPhaseBff extends IPhasedResource {
    Blocked: boolean;
    Progress: PhaseProgress;
    Deployments: GetReleasePhaseDeploymentBff[];
}
export interface GetReleasePhaseDeploymentBff {
    TaskId: string;
    TaskHasWarningsOrErrors: boolean;
    TaskState: TaskState;
    DeploymentEnvironmentId: string;
    TaskHasPendingInterruptions: boolean;
}
export interface GetReleaseDeploymentBff {
    EnvironmentId: string;
    ProjectId: string;
    TenantId?: string;
    TaskId: string;
    Id: string;
    Name: string;
}
