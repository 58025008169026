/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CSSInterpolation } from "@emotion/css";
import { css, cx } from "@emotion/css";
import Button from "@material-ui/core/Button";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import * as React from "react";
import { useIsBusy } from "../../hooks/useIsBusy";
import { noOp } from "../../utils/noOp";
import type { MenuTargetAriaAttributes } from "../Menu/useMenuState";
export interface ActionButtonProps {
    /**
     * The ref to the inner button element.
     */
    innerRef?: any;
    /**
     * The label of the button.
     *
     * This is not shown if any children are passed to the button.
     */
    label: string;
    /**
     * The accessible name of the button.
     */
    accessibleName?: string;
    /**
     * The title of the button.
     *
     * This is shown when hovering over the button.
     */
    title?: string;
    /**
     * When `true`, the `title` is not set on the button.
     */
    hideTitle?: boolean;
    /**
     * The label to show when the button is busy processing a click.
     */
    busyLabel?: string;
    /**
     * Controls whether the button is disabled.
     *
     * - Using a `boolean` will directly control whether the button is disabled.
     * - Using a `Promise` will set the button to disabled until the promise has been fulfilled.
     *
     * The button is automatically disabled when busy.
     */
    disabled?: Promise<unknown> | boolean | null;
    /**
     * Controls the appearance of the button.
     */
    type?: ActionButtonType;
    /**
     * The icon to show next to the label.
     */
    icon?: JSX.Element;
    /**
     * The position of the icon relative to the label.
     */
    iconPosition?: "left" | "right";
    /**
     * The tab index of the button.
     *
     * When omitted, the button will be keyboard focusable in sequential order.
     * This can only be set to `-1` which disables the keyboard focusability of the button.
     */
    tabIndex?: -1;
    /**
     * The classname to apply to the button.
     *
     * @deprecated Please avoid using this. If you need to add margin / other layout concerns
     * use a wrapping element instead. This property will be removed in the near future.
     */
    className?: string;
    /**
     * Controls whether the button is automatically focused when shown.
     */
    keyboardFocused?: boolean;
    /**
     * The CSS properties to apply to the button label.
     *
     * @deprecated Please avoid using this. This property will be removed in the near future.
     */
    labelProps?: React.CSSProperties;
    /**
     * The action to perform when the button is focused.
     */
    onFocus?(event: React.FocusEvent<HTMLElement>): void;
    /**
     * The action to perform when the button is clicked.
     *
     * If this function returns a promise, the button will be set to busy while the promise is pending.
     * When the button is busy, it is disabled and shows the `busyLabel` if provided.
     */
    onClick?(event: any): Promise<unknown> | void;
    /**
     * If the button opens a menu, these ARIA attributes should be provided for accessibility.
     */
    menuButtonAttributes?: MenuTargetAriaAttributes;
}
export enum ActionButtonType {
    Primary,
    Secondary,
    Ternary,
    Save,
    Delete,
    CreateRelease,
    Category
}
export function ActionButton({ innerRef, label, accessibleName, title, hideTitle, busyLabel, disabled: disabledPromise, type, icon, iconPosition = "left", tabIndex, className, keyboardFocused, labelProps, onFocus, onClick, menuButtonAttributes, children, }: React.PropsWithChildren<ActionButtonProps>) {
    const [onClickInvocation, setOnClickInvocation] = React.useState<Promise<unknown> | undefined>();
    const invokePromiseOnClick = React.useCallback(async (event: any) => {
        if (!onClick) {
            return;
        }
        if (event.preventDefault) {
            event.preventDefault();
        }
        const promise = onClick(event);
        setOnClickInvocation(promise ?? undefined);
    }, [onClick]);
    const isBusy = useIsBusy(onClickInvocation);
    const isDisabled = useIsBusy(disabledPromise) || isBusy;
    const getButton = () => {
        const customLabelStyles = labelProps
            ? css({
                [`&.${buttonClasses.root} .${buttonClasses.label}`]: {
                    ...labelProps,
                },
            })
            : undefined;
        const labelToShow = isBusy && busyLabel ? busyLabel : label;
        const titleToShow = title ? title : labelToShow;
        const childrenToShow = children ?? labelToShow;
        const otherPropsForButtons = {
            tabIndex: tabIndex,
            "aria-label": accessibleName,
        };
        const handleOnClick = isDisabled ? noOp : (e: any) => invokePromiseOnClick(e);
        switch (type) {
            case ActionButtonType.Primary:
                return (<Button ref={innerRef} variant="contained" disableElevation={true} disableRipple={true} type="submit" title={hideTitle ? undefined : titleToShow} disabled={isDisabled} startIcon={iconPosition === "left" ? icon : undefined} endIcon={iconPosition === "right" ? icon : undefined} onClick={handleOnClick} onFocus={onFocus} autoFocus={keyboardFocused} className={cx(primaryButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
            case ActionButtonType.Category:
                return (<Button ref={innerRef} variant="contained" disableElevation={true} disableRipple={true} type="submit" title={hideTitle ? undefined : titleToShow} disabled={isDisabled} startIcon={iconPosition === "left" ? icon : undefined} endIcon={iconPosition === "right" ? icon : undefined} onClick={handleOnClick} onFocus={onFocus} autoFocus={keyboardFocused} className={cx(categoryButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
            case ActionButtonType.CreateRelease:
                return (<Button ref={innerRef} variant="contained" disableElevation={true} disableRipple={true} type="submit" title={hideTitle ? undefined : titleToShow} disabled={isDisabled} onClick={handleOnClick} autoFocus={keyboardFocused} className={cx(createReleaseButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
            case ActionButtonType.Save:
                return (<Button ref={innerRef} variant="contained" disableElevation={true} disableRipple={true} type="submit" title={hideTitle ? undefined : titleToShow} disabled={isDisabled} onClick={handleOnClick} autoFocus={keyboardFocused} className={cx(primaryButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
            case ActionButtonType.Delete:
                return (<Button ref={innerRef} variant="contained" disableElevation={true} disableRipple={true} type="submit" title={hideTitle ? undefined : titleToShow} disabled={isDisabled} onClick={handleOnClick} autoFocus={keyboardFocused} className={cx(deleteButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
            case ActionButtonType.Ternary:
                return (<Button ref={innerRef} disableElevation={true} disableRipple={true} title={hideTitle ? undefined : titleToShow} startIcon={iconPosition === "left" ? icon : undefined} endIcon={iconPosition === "right" ? icon : undefined} disabled={isDisabled} onClick={handleOnClick} onFocus={onFocus} autoFocus={keyboardFocused} className={cx(ternaryButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
            // Fallthrough to Secondary
            default:
                return (<Button ref={innerRef} variant="contained" disableElevation={true} disableRipple={true} title={hideTitle ? undefined : titleToShow} startIcon={iconPosition === "left" ? icon : undefined} endIcon={iconPosition === "right" ? icon : undefined} disabled={isDisabled} onClick={handleOnClick} onFocus={onFocus} autoFocus={keyboardFocused} className={cx(secondaryButtonStyles, customLabelStyles, className)} {...otherPropsForButtons} {...menuButtonAttributes}>
                        {childrenToShow}
                    </Button>);
        }
    };
    return getButton();
}
const buttonClasses = {
    root: "MuiButton-root",
    label: "MuiButton-label",
    startIcon: "MuiButton-startIcon",
    endIcon: "MuiButton-endIcon",
    disabled: "Mui-disabled",
};
const iconClasses = {
    root: "MuiSvgIcon-root",
};
export const actionButtonRootCss: CSSInterpolation = {
    height: "2.25rem",
    minWidth: "5rem",
    borderRadius: 2,
    fill: "currentcolor",
    cursor: "pointer",
    display: "inline-flex",
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    justifyContent: "center",
    padding: "6px 16px",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontWeight: 500,
    lineHeight: 1.75,
    textTransform: "uppercase",
    verticalAlign: "middle",
    fontSize: "0.8125rem",
    whiteSpace: "nowrap",
    letterSpacing: 0,
    transitionProperty: "background",
    transitionDuration: "0.2s",
    ":focus-visible": {
        boxShadow: themeTokens.shadow.focus.default,
    },
};
export const actionButtonDisabledCss: CSSInterpolation = {
    backgroundColor: "transparent",
    color: themeTokens.color.actionButton.text.disabled,
    cursor: "not-allowed",
    pointerEvents: "auto",
    ":hover": {
        backgroundColor: "transparent",
        color: themeTokens.color.actionButton.text.disabled,
    },
};
export const actionButtonDisabledWithBackgroundCss: CSSInterpolation = {
    backgroundColor: themeTokens.color.actionButton.background.disabled,
    border: `1px solid ${themeTokens.color.actionButton.border.disabled}`,
    ":hover": {
        // MUI 4 annoyingly sets a background color on hover for disabled buttons so we need to override this.
        backgroundColor: themeTokens.color.actionButton.background.disabled,
    },
};
export const primaryActionButtonCss = getButtonCss({
    textColor: themeTokens.color.actionButton.text.primary,
    backgroundColor: themeTokens.color.actionButton.background.primary,
});
export const secondaryActionButtonCss = getButtonCss({
    textColor: themeTokens.color.actionButton.text.secondary,
    backgroundColor: themeTokens.color.actionButton.background.secondary,
    borderColor: themeTokens.color.actionButton.text.secondary,
});
export const ternaryActionButtonCss = getButtonCss({
    textColor: themeTokens.color.button.text.quiet.default,
    backgroundColor: themeTokens.color.button.background.quiet,
});
export const loudActionButtonCss = getButtonCss({
    textColor: themeTokens.color.button.text.loud.default,
    backgroundColor: themeTokens.color.button.background.loud,
});
const deleteActionButtonCss = getButtonCss({
    textColor: themeTokens.color.button.text.destructive.default,
    backgroundColor: themeTokens.color.button.background.destructive,
});
const createReleaseActionButtonCss = getButtonCss({
    textColor: themeTokens.color.actionButton.text.primary,
    backgroundColor: themeTokens.color.actionButton.background.createRelease,
});
const actionButtonIconStyles = css({
    [`&.${buttonClasses.root}`]: {
        [`.${iconClasses.root}`]: {
            fontSize: 24,
            // Some of our icon components hardcode margins into them for layout purposes elsewhere, we don't want this affecting the button layout
            margin: 0,
        },
        [`.${buttonClasses.startIcon}`]: {
            marginRight: 4,
        },
        [`.${buttonClasses.endIcon}`]: {
            marginLeft: 4,
            // The MUI 0 ActionButton shows the right icon with less space on the right since this is usually the DropDownIcon which has lots of padding built in.
            // So here we increase the default MUI4 marginRight from -4 to -8 to create similar spacing.
            marginRight: -8,
        },
    },
});
const actionButtonRootStyles = css(actionButtonIconStyles, {
    [`&.${buttonClasses.root}`]: actionButtonRootCss,
});
const actionButtonDisabledStyles = css({
    [`&.${buttonClasses.root}.${buttonClasses.disabled}`]: actionButtonDisabledCss,
});
const actionButtonDisabledWithBackgroundStyles = css(actionButtonDisabledStyles, {
    [`&.${buttonClasses.root}.${buttonClasses.disabled}`]: actionButtonDisabledWithBackgroundCss,
});
const primaryButtonStyles = css(actionButtonRootStyles, actionButtonDisabledWithBackgroundStyles, {
    [`&.${buttonClasses.root}`]: primaryActionButtonCss,
});
const secondaryButtonStyles = css(actionButtonRootStyles, actionButtonDisabledWithBackgroundStyles, {
    [`&.${buttonClasses.root}`]: secondaryActionButtonCss,
});
const ternaryButtonStyles = css(actionButtonRootStyles, actionButtonDisabledStyles, {
    [`&.${buttonClasses.root}`]: ternaryActionButtonCss,
});
const deleteButtonStyles = css(actionButtonRootStyles, actionButtonDisabledWithBackgroundStyles, {
    [`&.${buttonClasses.root}`]: deleteActionButtonCss,
});
const createReleaseButtonStyles = css(actionButtonRootStyles, actionButtonDisabledWithBackgroundStyles, {
    [`&.${buttonClasses.root}`]: createReleaseActionButtonCss,
});
const categoryButtonStyles = css(secondaryButtonStyles, {
    [`&.${buttonClasses.root}`]: {
        border: "none",
    },
});
interface CommonButtonTokens {
    backgroundColor: {
        default: string;
        hover: string;
        pressed: string;
    };
    textColor: string;
    borderColor?: string;
}
function getButtonCss(buttonTokens: CommonButtonTokens) {
    return {
        backgroundColor: buttonTokens.backgroundColor.default,
        color: buttonTokens.textColor,
        border: buttonTokens.borderColor ? `1px solid ${buttonTokens.borderColor}` : undefined,
        ":hover": {
            backgroundColor: buttonTokens.backgroundColor.hover,
            color: buttonTokens.textColor,
        },
        ":active": {
            backgroundColor: buttonTokens.backgroundColor.pressed,
            color: buttonTokens.textColor,
        },
    };
}
