import { routeTemplate, spaceIdPlaceholder } from "./RouteTemplate";
import { createPageRouteNew, createRouteSegment } from "./v2/routeBuilders";
export const allRoutes = {
    system: createRouteSegment(routeTemplate `${spaceIdPlaceholder}`, {
        configuration: createRouteSegment(routeTemplate `/configuration`, {
            licensePage: createPageRouteNew(routeTemplate `/license`, []),
            gitPage: createPageRouteNew(routeTemplate `/git`, []),
        }),
    }),
};
