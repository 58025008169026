import { css, cx } from "@emotion/css";
import { borderRadius, space, themeTokens, text } from "@octopusdeploy/design-system-tokens";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import { resetStyles } from "../../utils/resetStyles";
import type { ButtonImportance, ButtonSize } from "./Button";
export function getButtonStyles(importance: ButtonImportance, size: ButtonSize) {
    const buttonVariantStyles = getButtonVariantStyles(importance, size);
    return cx([baseButtonStyles, buttonVariantStyles]);
}
const baseButtonStyles = css({
    ...resetStyles.button,
    font: text.button.default.base,
    WebkitFontSmoothing: "antialiased",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    transitionTimingFunction: "ease-in-out",
    transitionProperty: "background-color, border-color, outline, color, fill",
    transitionDuration: "0.2s",
    borderRadius: borderRadius.medium,
    paddingInlineStart: space[12],
    paddingInlineEnd: space[12],
    whiteSpace: "nowrap",
});
function getButtonVariantStyles(importance: ButtonImportance, size: ButtonSize): string {
    const importanceLookup = getImportanceLookupValue(importance);
    const backgroundTokens = themeTokens.color.button.background[importanceLookup];
    const borderTokens = getBorderTokens(importanceLookup);
    const textTokens = themeTokens.color.button.text[importanceLookup];
    const iconTokens = themeTokens.color.button.icon[importanceLookup];
    const borderWidth = "1px";
    const basePaddingSize = size === "medium" ? space[8] : space[6];
    const padding = `calc(${basePaddingSize} - ${borderTokens ? borderWidth : "0px"})`;
    return css({
        color: textTokens.default,
        background: backgroundTokens.default,
        border: borderTokens ? `solid ${borderWidth} ${borderTokens.default}` : undefined,
        font: size === "medium" ? text.button.default.medium : text.button.quiet.medium,
        // we need to subtract the border width for the button to be the correct height (40px, not 42px)
        padding,
        svg: {
            fill: iconTokens.default,
        },
        ":hover": {
            background: backgroundTokens.hover,
            svg: {
                fill: hasHover(iconTokens) ? iconTokens.hover : iconTokens.default,
            },
        },
        ":focus-visible": {
            background: backgroundTokens.hover,
        },
        ":active": {
            background: backgroundTokens.pressed,
        },
        ":disabled": {
            color: textTokens.disabled,
            background: backgroundTokens.disabled,
            borderColor: borderTokens ? borderTokens.disabled : undefined,
            cursor: "not-allowed",
            svg: {
                fill: iconTokens.disabled,
            },
        },
    });
}
function getBorderTokens(importance: ButtonImportance) {
    switch (importance) {
        case "primary":
            return undefined;
        case "secondary":
            return themeTokens.color.button.border.secondary;
        case "tertiary":
            return themeTokens.color.button.border.tertiary;
        case "destructive":
            return undefined;
        case "quiet":
            return undefined;
        case "loud":
            return undefined;
        case "neutral":
            return undefined;
        default:
            exhaustiveCheck(importance, `Could not find lookup value for unknown variant: ${importance}`);
    }
}
function getImportanceLookupValue(importance: ButtonImportance): keyof typeof themeTokens.color.button.background {
    switch (importance) {
        case "primary":
            return "primary";
        case "secondary":
            return "secondary";
        case "tertiary":
            return "tertiary";
        case "destructive":
            return "destructive";
        case "quiet":
            return "quiet";
        case "loud":
            return "loud";
        case "neutral":
            return "neutral";
        default:
            exhaustiveCheck(importance, `Could not find lookup value for unknown variant: ${importance}`);
    }
}
export const labelStyles = css({
    marginRight: space[4],
    marginLeft: space[4],
});
type IconButtonWithoutHover = {
    default: string;
    disabled: string;
};
type IconButtonWithHover = IconButtonWithoutHover & {
    hover: string;
};
function hasHover(obj: IconButtonWithHover | IconButtonWithoutHover): obj is IconButtonWithHover {
    return "hover" in obj;
}
