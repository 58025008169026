import type { PageRouteDef, RouteSegment } from "./routeBuilders";
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageConstraint = PageRouteDef<any, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteSegmentConstraint = RouteSegment<any, any, any>;
type RouteSegmentsConstraint = Record<string, RouteSegmentConstraint>;
type PagesConstraint = Record<string, PageConstraint>;
export function createPageLinks<RouteParams, ChildRoutes extends RouteSegmentsConstraint, Pages extends PagesConstraint>(routes: RouteSegment<RouteParams, ChildRoutes, Pages>): PageLinks<ChildRoutes, Pages> {
    const pages = routes.pages;
    const childRoutes = routes.childRouteSegments;
    const childRouteLinks = Object.entries(childRoutes).reduce((p, [key, childRouteSegment]) => {
        return {
            ...p,
            [key]: createPageLinks(childRouteSegment),
        };
    }, {});
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return {
        ...pages,
        ...childRouteLinks,
    } as PageLinks<ChildRoutes, Pages>;
}
type PageLinks<ChildRoutes extends RouteSegmentsConstraint, Pages extends PagesConstraint> = {
    [K in keyof Pages]: Pages[K];
} & {
    [K in keyof ChildRoutes]: ChildRoutes[K] extends RouteSegment<any, infer ChildRoutes, infer Pages> ? PageLinks<ChildRoutes, Pages> : never;
};
