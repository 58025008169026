import { css } from "@emotion/css";
import { space, text, themeTokens } from "@octopusdeploy/design-system-tokens";
import { useOctopusPathResolver } from "@octopusdeploy/octopus-react-client";
import type { ProjectGroupResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import { exhaustiveCheck } from "@octopusdeploy/type-utils";
import React, { useState } from "react";
import { DropdownSwitcherListLayout } from "../Dropdown/DropdownSwitcherListLayout";
import { DropdownListItem } from "../List/DropdownListItem";
import type { ListElement } from "../List/List";
import { List } from "../List/List";
import { NoSearchResults } from "../Search/NoSearchResults";
import type { ProjectsState, RecentAndAllProjectsState } from "./ControlledProjectSwitcher";
import { NoProjects } from "./NoProjects";
import type { ProjectSummary } from "./ProjectSummary";
import { useTrackProjectSearchStartedCallback } from "./analytics/useDispatchProjectSwitcherInteraction";
export interface ProjectsPanelProps {
    projectsState: ProjectsState;
    projectGroupMap: ResourcesById<ProjectGroupResource>;
    getProjectHref: (project: ProjectSummary) => string;
    onFilterChange: (value: string) => void;
    isLoading: boolean;
    errorAlert: React.ReactNode | undefined;
    onProjectClick: () => void;
}
const MAX_PROJECTS_TO_DISPLAY = 50;
const MAX_RECENT_PROJECTS_TO_DISPLAY = 10;
export function ProjectsPanel({ projectsState, projectGroupMap, getProjectHref, onFilterChange, isLoading, errorAlert, onProjectClick }: ProjectsPanelProps) {
    const trackProjectSearchStarted = useTrackProjectSearchStartedCallback();
    const [searchInput, setSearchInput] = useState("");
    React.useEffect(() => {
        onFilterChange(searchInput);
    }, [onFilterChange, searchInput]);
    const handleSearchInputChange = (value: string) => {
        if (value !== "") {
            trackProjectSearchStarted();
        }
        setSearchInput(value);
    };
    return (<DropdownSwitcherListLayout searchPlaceholder="Quick search all projects" searchValue={searchInput} onSearchValueChanged={handleSearchInputChange} searchAccessibleName="Search projects" renderList={(listRef) => getProjectsContent(projectsState, getProjectHref, projectGroupMap, searchInput, listRef, onProjectClick)} isLoading={isLoading} errorAlert={errorAlert}/>);
}
function getProjectsContent(projectsState: ProjectsState, getProjectHref: (project: ProjectSummary) => string, projectGroupMap: ResourcesById<ProjectGroupResource>, searchInput: string, listRef: React.Ref<ListElement>, onProjectClick: () => void) {
    const { type } = projectsState;
    switch (type) {
        case "recently-viewed-and-all":
            return <RecentAndAllProjects firstListRef={listRef} getProjectHref={getProjectHref} projectGroupMap={projectGroupMap} projectsState={projectsState} onProjectClick={onProjectClick}/>;
        case "filtered":
            const limitedFilteredResults = projectsState.filteredProjects.slice(0, MAX_PROJECTS_TO_DISPLAY);
            const hasSearchResults = limitedFilteredResults.length > 0;
            const headerText = limitedFilteredResults.length < projectsState.filteredProjects.length
                ? `${limitedFilteredResults.length} results of ${projectsState.filteredProjects.length} for "${searchInput}"`
                : `${limitedFilteredResults.length} results for "${searchInput}"`;
            return (<ProjectsList headerText={hasSearchResults ? headerText : undefined} projects={limitedFilteredResults} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} accessibleName="Filtered projects" onProjectClick={onProjectClick} emptyView={<NoSearchResults searchInput={searchInput}/>} listRef={listRef}/>);
        case "empty":
            return <NoProjects projectsState={projectsState}/>;
        case "initially-loading":
            return null;
        default:
            exhaustiveCheck(type, `Missing content for projects state type: ${type}`);
    }
}
interface RecentAndAllProjectsProps extends Pick<ProjectsPanelProps, "projectGroupMap" | "getProjectHref" | "onProjectClick"> {
    projectsState: RecentAndAllProjectsState;
    firstListRef: React.Ref<ListElement>;
}
function RecentAndAllProjects({ projectsState, projectGroupMap, getProjectHref, firstListRef, onProjectClick }: RecentAndAllProjectsProps) {
    const { recentProjects, allProjectsPageOne, totalProjects } = projectsState;
    const limitedRecentProjects = recentProjects.slice(0, MAX_RECENT_PROJECTS_TO_DISPLAY);
    const limitedProjects = allProjectsPageOne.slice(0, MAX_PROJECTS_TO_DISPLAY);
    return (<>
            <ProjectsList headerText="Recently viewed" projects={limitedRecentProjects} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} accessibleName="Recently viewed projects" listRef={limitedRecentProjects.length > 0 ? firstListRef : undefined} emptyView={<span className={listEmptyTextStyles}>Your recently viewed projects will appear here.</span>} onProjectClick={onProjectClick}/>
            <ProjectsList headerText="All projects" headerSubText={`Showing ${limitedProjects.length} of ${totalProjects}`} projects={limitedProjects} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} accessibleName="All projects" listRef={recentProjects.length > 0 ? undefined : firstListRef} onProjectClick={onProjectClick}/>
        </>);
}
interface ListHeaderProps {
    text: string;
    subText?: string;
}
const ListHeader = ({ text, subText }: ListHeaderProps) => (<div className={listHeaderStyles}>
        <span className={listHeaderTextStyles}>{text}</span>
        {subText && <span className={listHeaderSubTextStyles}>{subText}</span>}
    </div>);
interface ProjectsListProps {
    headerText?: string;
    headerSubText?: string;
    projects: ProjectSummary[];
    projectGroupMap: ResourcesById<ProjectGroupResource>;
    getProjectHref: (project: ProjectSummary) => string;
    accessibleName: string;
    emptyView?: React.ReactNode;
    listRef: React.Ref<ListElement> | undefined;
    onProjectClick: () => void;
}
const ProjectsList = ({ headerText, headerSubText, projects, projectGroupMap, getProjectHref, accessibleName, emptyView, listRef, onProjectClick }: ProjectsListProps) => {
    const resolvePath = useOctopusPathResolver();
    return (<>
            {headerText && <ListHeader text={headerText} subText={headerSubText}/>}
            {projects.length > 0 ? (<List items={projects} renderListItem={(project, index, tabIndex, ref) => (<DropdownListItem key={project.Id} title={project.Name} description={project.GroupId ? projectGroupMap[project.GroupId]?.Name : undefined} href={getProjectHref(project)} logo={{ href: resolvePath(project.Logo), accessibleName: `${project.Name} logo` }} tabIndex={tabIndex} ref={ref} onClick={onProjectClick}/>)} accessibleName={accessibleName} ref={listRef}/>) : (emptyView)}
        </>);
};
const listHeaderStyles = css({
    paddingTop: space[24],
    paddingLeft: space[16],
    paddingBottom: space[8],
});
const listHeaderTextStyles = css({
    font: text.interface.heading.small,
    color: themeTokens.color.text.primary,
});
const listHeaderSubTextStyles = css({
    font: text.interface.body.default.medium,
    color: themeTokens.color.text.subtle,
    marginLeft: space[8],
});
const listEmptyTextStyles = css({
    font: text.interface.body.default.medium,
    color: themeTokens.color.text.subtle,
    paddingLeft: space[12],
    paddingBottom: space[12],
});
