/* eslint-disable @typescript-eslint/consistent-type-assertions */
import type { Client } from "../client";
import type { GetTeamsLookupBffResponse, GetTeamsLookupBffResponseTeam, ResourceCollection, ScopedUserRoleResource, TeamResource } from "../resources";
import type { ListArgs } from "./basicRepository";
import { MixedScopeBaseRepository } from "./mixedScopeBaseRepository";
interface TeamListArgs extends ListArgs {
    spaces?: string | string[];
    ids?: string | string[];
    includeSystem?: boolean;
    partialName: string;
}
export class TeamRepository extends MixedScopeBaseRepository<TeamResource, TeamResource> {
    constructor(client: Client) {
        super("Teams", client);
    }
    listScopedUserRoles(team: TeamResource): Promise<ResourceCollection<ScopedUserRoleResource>> {
        return this.client.get(team.Links["ScopedUserRoles"], this.spacePartitionParameters());
    }
    list(args?: TeamListArgs): Promise<ResourceCollection<TeamResource>> {
        return super.list(args) as Promise<ResourceCollection<TeamResource>>;
    }
    async lookupBff(ids?: string[]): Promise<GetTeamsLookupBffResponseTeam[]> {
        // If there are a large number of arguments, return everything
        const args = ids && ids.length <= 500 ? { ids } : {};
        const combinedArgs = super.extend(this.spacePartitionParameters(), args);
        const response = await this.client.get<GetTeamsLookupBffResponse>("~/bff/teams/lookup/{?ids,spaces,includeSystem}", combinedArgs);
        return response.Teams;
    }
}
