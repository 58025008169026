import { css, cx } from "@emotion/css";
import { Slide, Snackbar as MaterialSnackbar } from "@material-ui/core";
import type { SlideProps } from "@material-ui/core/Slide/Slide";
import { themeTokens } from "@octopusdeploy/design-system-tokens";
import React from "react";
export interface SnackbarProps {
    /**
     * Controls whether the Snackbar is being shown.
     */
    open: boolean;
    /**
     * Controls the width of the snackbar. Use `"large"` for longer content.
     */
    size?: Size;
    /**
     * Specifies how long the Snackbar will remain open, in ms. After the time has elapsed, the Snackbar will invoke the `onClose` callback.
     */
    autoHideDuration?: number;
    /**
     * The content or message that will be displayed within the Snackbar.
     */
    content: string | React.ReactNode;
    /**
     * The callback is invoked when the `autoHideDuration` has elapsed, or when the user clicks anywhere outside the Snackbar. This callback should be used to change the `open` state, controlled in a parent component.
     */
    onClose?(): void;
    /**
     * Controls the text alignment of the content within the Snackbar.
     */
    textAlign?: TextAlign;
}
export type Size = "large" | "small";
export type TextAlign = "center" | "left";
export function Snackbar({ open, content, size, onClose, autoHideDuration, textAlign }: SnackbarProps) {
    const message = typeof content === "string" ? content : undefined;
    // We need to wrap children in a <div> because MaterialSnackbar requires it child to always have a ref property
    const children = typeof content === "string" ? undefined : <div>{content}</div>;
    return (<MaterialSnackbar open={open} message={message} classes={{ root: cx({ [wideStyles]: size === "large" }) }} autoHideDuration={autoHideDuration} onClose={onClose} TransitionComponent={SlideUp} ContentProps={{ classes: { root: cx(contentStyles, { [alignedCenterStyles]: textAlign === "center" }) } }}>
            {children}
        </MaterialSnackbar>);
}
const materialSnackbarClasses = {
    root: "MuiSnackbar-root",
    contentRoot: "MuiSnackbarContent-root",
    contentMessage: "MuiSnackbarContent-message",
};
const wideStyles = css({
    [`&.${materialSnackbarClasses.root}`]: {
        width: "60%",
        margin: "1.5em",
        bottom: 0,
        display: "block",
    },
});
const alignedCenterStyles = css({
    [`.${materialSnackbarClasses.contentMessage}`]: {
        textAlign: "center",
        width: "100%",
    },
});
const contentStyles = css({
    [`&.${materialSnackbarClasses.contentRoot}`]: {
        backgroundColor: themeTokens.color.background.inverse.primary,
        color: themeTokens.color.text.inverse,
    },
});
function SlideUp(props: Omit<SlideProps, "direction">) {
    return <Slide {...props} direction="up"/>;
}
