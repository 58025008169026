import type { Client } from "../client";
import type { GitHubAppAuthorizationStatus, GitHubAppConnectionResource, GitHubAppConnections, GitHubAppInstallations, GitHubAppRepositories } from "../resources";
export interface CreateGitHubAppConnectionCommand {
    InstallationId: string;
    RepositoryIds: string[];
}
export interface ModifyGitHubAppConnectionCommand {
    RepositoryIds: string[];
}
export interface CreateGitHubAppConnectionResponse {
    ConnectionId: string;
}
export class GitHubAppRepository {
    protected client: Client;
    constructor(client: Client) {
        this.client = client;
    }
    getAuthorizeAppUrl(redirectUri: string): string {
        return this.client.resolve("~/api/github/user/app/authorize{?redirectUri}", { redirectUri });
    }
    getInstallationUrl(redirectUri: string): string {
        return this.client.resolve("~/api/github/accounts/install-url{?redirectUri}", { redirectUri });
    }
    getInstallations() {
        return this.client.get<GitHubAppInstallations>("~/api/{spaceId}/github/installations");
    }
    getRepositories(installationId: string) {
        return this.client.get<GitHubAppRepositories>("~/api/{spaceId}/github/installations/{installationId}/repositories", { installationId });
    }
    getConnection(id: string) {
        return this.client.get<GitHubAppConnectionResource>("~/api/{spaceId}/github/connections/{id}", { id });
    }
    addConnection(command: CreateGitHubAppConnectionCommand) {
        return this.client.post<CreateGitHubAppConnectionResponse>("~/api/{spaceId}/github/connections", command);
    }
    modifyConnection(id: string, command: ModifyGitHubAppConnectionCommand) {
        return this.client.put("~/api/{spaceId}/github/connections/{id}", command, { id });
    }
    getConnections(skip: number, take: number): Promise<GitHubAppConnections> {
        const args = { skip, take };
        return this.client.get<GitHubAppConnections>("~/api/{spaceId}/github/connections{?skip}{&take}", args);
    }
    getAuthorizationStatus(): Promise<GitHubAppAuthorizationStatus> {
        return this.client.get<GitHubAppAuthorizationStatus>("~/api/github/user/app/authorization_status");
    }
}
