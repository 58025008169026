import { css } from "@emotion/css";
import { space, themeTokens } from "@octopusdeploy/design-system-tokens";
import React, { useState } from "react";
import { IconButton } from "../IconButton";
export interface CarouselProps {
    steps: React.ReactElement[];
    initialStep?: number;
    accessibleName?: string;
    onInteraction?: (stepKey: string) => void;
}
const carouselStyles = {
    carousel: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-evenly",
        height: "100%",
        gap: space[24],
    }),
    carouselContentContainer: css({ overflowX: "hidden", width: "100%" }),
    carouselAnimationContainer: css({
        display: "flex",
        flexDirection: "row",
        direction: "ltr",
        transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s;",
    }),
    stepContent: css({
        display: "flex",
        width: "100%",
        overflow: "auto",
        flexShrink: 0,
    }),
};
export function Carousel({ steps, initialStep, accessibleName, onInteraction }: CarouselProps) {
    const [activeStep, setActiveStep] = useState<number>(initialStep && steps[initialStep] ? initialStep : 0);
    const accessibleContainerName = accessibleName ?? "carousel";
    const nextStep = () => {
        if (activeStep < steps.length - 1) {
            jumpToStep(activeStep + 1);
        }
    };
    const previousStep = () => {
        if (activeStep > 0) {
            jumpToStep(activeStep - 1);
        }
    };
    const jumpToStep = (stepNumber: number) => {
        setActiveStep(stepNumber);
        if (onInteraction) {
            const stepKey = steps[stepNumber].key?.toString() ?? stepNumber.toString();
            onInteraction(stepKey);
        }
    };
    const transitionAnimation = css(carouselStyles.carouselAnimationContainer, {
        transform: `translate(-${activeStep > 0 ? activeStep * 100 : 0}%, 0px)`,
    });
    return (<section className={carouselStyles.carousel} aria-label={accessibleContainerName} aria-roledescription="carousel">
            <div className={carouselStyles.carouselContentContainer}>
                <div className={transitionAnimation}>
                    {steps.map((step, index) => (<div role="tabpanel" aria-roledescription="slide" aria-label={`slide ${index}`} key={step.key ?? index} className={carouselStyles.stepContent} aria-hidden={index !== activeStep}>
                            {step}
                        </div>))}
                </div>
            </div>
            <Stepper backButton={<IconButton onClick={previousStep} icon="ChevronLeft" disabled={activeStep === 0}/>} nextButton={<IconButton onClick={nextStep} icon="ChevronRight" disabled={activeStep === steps.length - 1}/>} totalSteps={steps.length} activeStep={activeStep} onStepDotClick={jumpToStep} accessibleName={accessibleContainerName}/>
        </section>);
}
interface StepperProps {
    backButton: React.ReactNode;
    nextButton: React.ReactNode;
    totalSteps: number;
    activeStep: number;
    onStepDotClick: (stepNumber: number) => void;
    accessibleName?: string;
}
const stepperStyles = {
    stepper: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        height: 28,
        gap: space[24],
    }),
    stepperDotContainer: css({
        display: "inline-flex",
        gap: 12,
    }),
    stepperDot: css({
        borderRadius: "50%",
        width: 12,
        height: 12,
        backgroundColor: themeTokens.color.carousel.icon.default,
        cursor: "pointer",
        "&:hover": {
            backgroundColor: themeTokens.color.carousel.icon.hover,
        },
    }),
};
const stepperActiveDotStyles = css(stepperStyles.stepperDot, {
    backgroundColor: themeTokens.color.carousel.icon.selected,
});
function Stepper({ backButton, nextButton, totalSteps, activeStep, onStepDotClick, accessibleName }: StepperProps) {
    const steps = [...Array(totalSteps).keys()];
    const accessibleContainerName = accessibleName ? `${accessibleName} slides` : "slides";
    return (<div className={stepperStyles.stepper} role="tablist" aria-label={accessibleContainerName}>
            {backButton}
            <div className={stepperStyles.stepperDotContainer}>
                {steps.map((val) => (<span role="tab" aria-label={`slide ${val}`} aria-selected={val === activeStep} key={val} onClick={() => onStepDotClick(val)} className={val === activeStep ? stepperActiveDotStyles : stepperStyles.stepperDot}></span>))}
            </div>
            {nextButton}
        </div>);
}
