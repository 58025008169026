/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { DeploymentActionContainer } from "./deploymentActionContainer";
import type { LinksCollection } from "./linksCollection";
import type { NamedResource, NewNamedResource } from "./namedResource";
import type { OfflineDropDestinationResource } from "./offlineDropDestinationResource";
import type { SensitiveValue } from "./propertyValueResource";
import type { StepPackageInputs } from "./stepPackageInputs";
export type UnstructuredDeploymentTargetResourceInputs = Record<string, unknown> | undefined;
export enum CommunicationStyle {
    None = "None",
    TentaclePassive = "TentaclePassive",
    TentacleActive = "TentacleActive",
    Ssh = "Ssh",
    OfflineDrop = "OfflineDrop",
    AzureWebApp = "AzureWebApp",
    AzureCloudService = "AzureCloudService",
    AzureServiceFabricCluster = "AzureServiceFabricCluster",
    Kubernetes = "Kubernetes",
    StepPackage = "StepPackage"
}
// Emulating a resource so we can refer to this enum in dropdownlists easily etc.
export interface CommunicationStyleResource {
    Id: CommunicationStyle;
    Name: string;
}
export interface TentacleDetailsResource {
    UpgradeLocked: boolean;
    Version: string;
    UpgradeSuggested: boolean;
    UpgradeRequired: boolean;
    UpgradeAvailable: boolean;
}
export interface EndpointResource extends NamedResource {
    CommunicationStyle: CommunicationStyle;
}
export interface NewEndpointResource extends NewNamedResource {
    CommunicationStyle: CommunicationStyle;
}
export interface TentacleEndpointResource extends EndpointResource {
    Thumbprint: string;
    TentacleVersionDetails: TentacleDetailsResource;
    CertificateSignatureAlgorithm: string;
}
export interface ListeningTentacleEndpointResource extends TentacleEndpointResource {
    CommunicationStyle: CommunicationStyle.TentaclePassive;
    Uri: string;
    ProxyId: string | undefined;
}
export interface PollingTentacleEndpointResource extends TentacleEndpointResource {
    CommunicationStyle: CommunicationStyle.TentacleActive;
    Uri: string;
}
export type AgentlessEndpointResource = EndpointResource;
export interface AzureWebAppEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.AzureWebApp;
    AccountId: string;
    ResourceGroupName: string | undefined;
    WebAppName: string | undefined;
    WebAppSlotName: string | undefined;
    DefaultWorkerPoolId: string | undefined;
}
export interface IEndpointWithMultipleAuthentication {
    AuthenticationType: string;
}
export enum KubernetesAuthenticationType {
    KubernetesStandard = "KubernetesStandard",
    KubernetesCertificate = "KubernetesCertificate",
    KubernetesPodServiceAccount = "KubernetesPodService",
    KubernetesAzure = "KubernetesAzure",
    KubernetesAws = "KubernetesAws",
    KubernetesGoogleCloud = "KubernetesGoogleCloud"
}
export class KubernetesCertificateAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string = undefined!;
    ClientCertificate: string = undefined!;
    constructor() {
        this.AuthenticationType = KubernetesAuthenticationType.KubernetesCertificate;
    }
}
export class KubernetesPodServiceAccountAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string = undefined!;
    TokenPath: string = undefined!;
    constructor() {
        this.AuthenticationType = KubernetesAuthenticationType.KubernetesPodServiceAccount;
    }
}
export class KubernetesStandardAccountAuthentication implements IEndpointWithMultipleAuthentication {
    AuthenticationType: string = undefined!;
    AccountId: string = undefined!;
    constructor() {
        this.AuthenticationType = KubernetesAuthenticationType.KubernetesStandard;
    }
}
export class KubernetesAzureAuthentication extends KubernetesStandardAccountAuthentication {
    ClusterName: string = undefined!;
    ClusterResourceGroup: string = undefined!;
    AdminLogin: string = undefined!;
    constructor() {
        super();
        this.AuthenticationType = KubernetesAuthenticationType.KubernetesAzure;
    }
}
export class KubernetesAwsAuthentication extends KubernetesStandardAccountAuthentication {
    UseInstanceRole: boolean = undefined!;
    AssumeRole: boolean = undefined!;
    AssumedRoleArn: string = undefined!;
    AssumedRoleSession: string = undefined!;
    AssumeRoleSessionDurationSeconds: number | null = undefined!;
    AssumeRoleExternalId: string = undefined!;
    ClusterName: string = undefined!;
    constructor() {
        super();
        this.AuthenticationType = KubernetesAuthenticationType.KubernetesAws;
    }
}
export class KubernetesGoogleCloudAuthentication extends KubernetesStandardAccountAuthentication {
    UseVmServiceAccount: boolean = false;
    ImpersonateServiceAccount: boolean = false;
    ServiceAccountEmails: string = undefined!;
    Project: string = undefined!;
    Region: string = undefined!;
    Zone: string = undefined!;
    ClusterName: string = undefined!;
    UseClusterInternalIp: boolean = false;
    constructor() {
        super();
        this.AuthenticationType = KubernetesAuthenticationType.KubernetesGoogleCloud;
    }
}
export interface KubernetesEndpointResource extends AgentlessEndpointResource {
    Id: string;
    Links: LinksCollection;
    Name: string;
    AccountType: string;
    ClusterUrl: string;
    ClusterCertificate: string;
    ClusterCertificatePath: string;
    Namespace: string;
    SkipTlsVerification: string;
    ProxyId: string;
    DefaultWorkerPoolId: string | undefined;
    CommunicationStyle: CommunicationStyle.Kubernetes;
    Authentication: KubernetesAwsAuthentication | KubernetesAzureAuthentication | KubernetesStandardAccountAuthentication | KubernetesCertificateAuthentication | KubernetesPodServiceAccountAuthentication | KubernetesGoogleCloudAuthentication | null;
    Container: DeploymentActionContainer | null;
}
export interface AzureServiceFabricClusterEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.AzureServiceFabricCluster;
    ConnectionEndpoint: string;
    SecurityMode: AzureServiceFabricSecurityMode;
    ServerCertThumbprint: string;
    ClientCertVariable: string;
    CertificateStoreLocation: string;
    CertificateStoreName: string;
    AadCredentialType: AADCredentialType;
    AadClientCredentialSecret: string;
    AadUserCredentialUsername: string;
    AadUserCredentialPassword: SensitiveValue;
    DefaultWorkerPoolId: string | undefined;
}
export enum AzureServiceFabricSecurityMode {
    Unsecure = "Unsecure",
    SecureClientCertificate = "SecureClientCertificate",
    SecureAzureAD = "SecureAzureAD"
}
export enum AADCredentialType {
    ClientCredential = "ClientCredential",
    UserCredential = "UserCredential"
}
export enum AzureCloudServiceEndpointDeploymentSlot {
    Staging = "Staging",
    Production = "Production"
}
export interface AzureCloudServiceEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.AzureCloudService;
    AccountId: string;
    CloudServiceName: string;
    DefaultWorkerPoolId: string | undefined;
    StorageAccountName: string;
    Slot: AzureCloudServiceEndpointDeploymentSlot;
    SwapIfPossible: boolean;
    UseCurrentInstanceCount: boolean;
}
export interface CloudRegionEndpointResource extends AgentlessEndpointResource {
    DefaultWorkerPoolId: string | undefined;
}
export interface OfflineDropEndpointResource extends AgentlessEndpointResource {
    Destination: OfflineDropDestinationResource;
    SensitiveVariablesEncryptionPassword: SensitiveValue;
    ApplicationsDirectory: string;
    OctopusWorkingDirectory: string;
}
export interface SshEndpointResource extends AgentlessEndpointResource {
    AccountId: string;
    Host: string;
    Port: number;
    Fingerprint: string;
    Uri: string;
    ProxyId: string | undefined;
    DotNetCorePlatform: string | undefined;
    HostKeyAlgorithm: string;
}
export interface StepPackageEndpointResource extends AgentlessEndpointResource {
    CommunicationStyle: CommunicationStyle.StepPackage;
    DeploymentTargetTypeId: string;
    StepPackageVersion: string;
    Inputs: StepPackageInputs;
    DefaultWorkerPoolId?: string;
}
export function isStepPackageEndpointResource(resource: EndpointResource): resource is StepPackageEndpointResource {
    return "StepPackageId" in resource && "DeploymentTargetTypeId" in resource;
}
