import * as globals from "./globals";
import type { OctopusTheme } from "./OctopusTheme";
export const lightTheme: OctopusTheme = {
    "colorAlertTextInfo": globals["colorScales"]["blue"]["600"],
    "colorAlertTextSuccess": globals["colorScales"]["green"]["600"],
    "colorAlertTextWarning": globals["colorScales"]["yellow"]["600"],
    "colorAlertTextDanger": globals["colorScales"]["red"]["600"],
    "colorAlertTextBody": globals["colorScales"]["black"],
    "colorAlertIconInfo": globals["colorScales"]["blue"]["600"],
    "colorAlertIconSuccess": globals["colorScales"]["green"]["600"],
    "colorAlertIconWarning": globals["colorScales"]["yellow"]["600"],
    "colorAlertIconDanger": globals["colorScales"]["red"]["600"],
    "colorAlertIconDismiss": globals["colorScales"]["navy"]["700"],
    "colorAlertBorderInfo": globals["colorScales"]["blue"]["500"],
    "colorAlertBorderSuccess": globals["colorScales"]["green"]["500"],
    "colorAlertBorderWarning": globals["colorScales"]["yellow"]["500"],
    "colorAlertBorderDanger": globals["colorScales"]["red"]["500"],
    "colorAlertBackgroundInfo": globals["colorScales"]["blue"]["100"],
    "colorAlertBackgroundSuccess": globals["colorScales"]["green"]["100"],
    "colorAlertBackgroundWarning": globals["colorScales"]["yellow"]["100"],
    "colorAlertBackgroundDanger": globals["colorScales"]["red"]["100"],
    "colorMenuListTextPrimary": globals["colorScales"]["grey"]["800"],
    "colorMenuListTextSecondary": globals["colorScales"]["grey"]["600"],
    "colorMenuListTextHighlight": globals["colorScales"]["blue"]["500"],
    "colorMenuListBackgroundDefault": globals["colorScales"]["white"],
    "colorMenuListBackgroundHover": globals["colorScales"]["grey"]["100"],
    "colorMenuListIconPrimary": globals["colorScales"]["grey"]["500"],
    "colorTextFieldTextPrimary": globals["colorScales"]["grey"]["800"],
    "colorTextFieldTextMuted": globals["colorScales"]["grey"]["500"],
    "colorTextFieldTextSelected": globals["colorScales"]["blue"]["500"],
    "colorTextFieldTextDanger": globals["colorScales"]["red"]["500"],
    "colorTextFieldTextDisabled": globals["colorScales"]["grey"]["300"],
    "colorTextFieldBorderDefault": globals["colorScales"]["grey"]["300"],
    "colorTextFieldBorderHover": globals["colorScales"]["grey"]["500"],
    "colorTextFieldBorderActive": globals["colorScales"]["blue"]["500"],
    "colorTextFieldBorderDanger": globals["colorScales"]["red"]["500"],
    "colorTextFieldBorderDisabled": globals["colorScales"]["grey"]["300"],
    "colorTextFieldIconPrimary": globals["colorScales"]["grey"]["700"],
    "colorTextFieldIconDisabled": globals["colorScales"]["grey"]["300"],
    "colorChipFilledTextPrimary": globals["colorScales"]["navy"]["600"],
    "colorChipFilledTextSecondary": globals["colorScales"]["purple"]["600"],
    "colorChipFilledTextInfo": globals["colorScales"]["blue"]["600"],
    "colorChipFilledTextSuccess": globals["colorScales"]["green"]["600"],
    "colorChipFilledTextWarning": globals["colorScales"]["yellow"]["600"],
    "colorChipFilledTextDanger": globals["colorScales"]["red"]["600"],
    "colorChipFilledBackgroundPrimary": globals["colorScales"]["navy"]["200"],
    "colorChipFilledBackgroundSecondary": globals["colorScales"]["purple"]["200"],
    "colorChipFilledBackgroundInfo": globals["colorScales"]["blue"]["200"],
    "colorChipFilledBackgroundSuccess": globals["colorScales"]["green"]["200"],
    "colorChipFilledBackgroundWarning": globals["colorScales"]["yellow"]["200"],
    "colorChipFilledBackgroundDanger": globals["colorScales"]["red"]["200"],
    "colorChipFilledIconPrimary": globals["colorScales"]["navy"]["600"],
    "colorChipFilledIconSecondary": globals["colorScales"]["purple"]["600"],
    "colorChipFilledIconInfo": globals["colorScales"]["blue"]["600"],
    "colorChipFilledIconSuccess": globals["colorScales"]["green"]["600"],
    "colorChipFilledIconWarning": globals["colorScales"]["yellow"]["600"],
    "colorChipFilledIconDanger": globals["colorScales"]["red"]["600"],
    "colorChipOutlinedBorderPrimary": globals["colorScales"]["navy"]["500"],
    "colorChipOutlinedBorderSecondary": globals["colorScales"]["purple"]["500"],
    "colorChipOutlinedBorderInfo": globals["colorScales"]["blue"]["500"],
    "colorChipOutlinedBorderSuccess": globals["colorScales"]["green"]["500"],
    "colorChipOutlinedBorderWarning": globals["colorScales"]["yellow"]["500"],
    "colorChipOutlinedBorderDanger": globals["colorScales"]["red"]["500"],
    "colorChipOutlinedTextPrimary": globals["colorScales"]["navy"]["500"],
    "colorChipOutlinedTextSecondary": globals["colorScales"]["purple"]["500"],
    "colorChipOutlinedTextInfo": globals["colorScales"]["blue"]["500"],
    "colorChipOutlinedTextSuccess": globals["colorScales"]["green"]["500"],
    "colorChipOutlinedTextWarning": globals["colorScales"]["yellow"]["500"],
    "colorChipOutlinedTextDanger": globals["colorScales"]["red"]["500"],
    "colorChipOutlinedIconPrimary": globals["colorScales"]["navy"]["500"],
    "colorChipOutlinedIconSecondary": globals["colorScales"]["purple"]["500"],
    "colorChipOutlinedIconInfo": globals["colorScales"]["blue"]["500"],
    "colorChipOutlinedIconSuccess": globals["colorScales"]["green"]["500"],
    "colorChipOutlinedIconWarning": globals["colorScales"]["yellow"]["500"],
    "colorChipOutlinedIconDanger": globals["colorScales"]["red"]["500"],
    "colorButtonBackgroundPrimaryDefault": globals["colorScales"]["green"]["500"],
    "colorButtonBackgroundPrimaryHover": globals["colorScales"]["green"]["600"],
    "colorButtonBackgroundPrimaryPressed": globals["colorScales"]["green"]["700"],
    "colorButtonBackgroundPrimaryDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonBackgroundSecondaryDefault": "rgba(85, 121, 153, 0.06)",
    "colorButtonBackgroundSecondaryHover": "rgba(117, 117, 117, 0.16)",
    "colorButtonBackgroundSecondaryPressed": "rgba(117, 117, 117, 0.24)",
    "colorButtonBackgroundSecondaryDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonBackgroundTertiaryDefault": "rgba(26, 119, 202, 0.0)",
    "colorButtonBackgroundTertiaryHover": "rgba(26, 119, 202, 0.08)",
    "colorButtonBackgroundTertiaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorButtonBackgroundTertiaryDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonBackgroundDestructiveDefault": globals["colorScales"]["red"]["500"],
    "colorButtonBackgroundDestructiveHover": globals["colorScales"]["red"]["600"],
    "colorButtonBackgroundDestructivePressed": globals["colorScales"]["red"]["700"],
    "colorButtonBackgroundDestructiveDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonBackgroundQuietDefault": "rgba(255, 255, 255, 0.0)",
    "colorButtonBackgroundQuietHover": "rgba(26, 119, 202, 0.08)",
    "colorButtonBackgroundQuietPressed": "rgba(26, 119, 202, 0.24)",
    "colorButtonBackgroundQuietDisabled": "rgba(255, 255, 255, 0.0)",
    "colorButtonBackgroundLoudDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonBackgroundLoudHover": globals["colorScales"]["blue"]["600"],
    "colorButtonBackgroundLoudPressed": globals["colorScales"]["blue"]["700"],
    "colorButtonBackgroundLoudDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonBackgroundNeutralDefault": globals["colorScales"]["grey"]["100"],
    "colorButtonBackgroundNeutralHover": globals["colorScales"]["grey"]["200"],
    "colorButtonBackgroundNeutralPressed": globals["colorScales"]["grey"]["300"],
    "colorButtonBackgroundNeutralDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonIconPrimaryDefault": globals["colorScales"]["white"],
    "colorButtonIconPrimaryDisabled": globals["colorScales"]["white"],
    "colorButtonIconSecondaryDefault": globals["colorScales"]["grey"]["500"],
    "colorButtonIconSecondaryHover": globals["colorScales"]["grey"]["700"],
    "colorButtonIconSecondaryDisabled": globals["colorScales"]["white"],
    "colorButtonIconTertiaryDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonIconTertiaryDisabled": globals["colorScales"]["white"],
    "colorButtonIconDestructiveDefault": globals["colorScales"]["white"],
    "colorButtonIconDestructiveDisabled": globals["colorScales"]["white"],
    "colorButtonIconQuietDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonIconQuietHover": globals["colorScales"]["blue"]["500"],
    "colorButtonIconQuietDisabled": globals["colorScales"]["grey"]["300"],
    "colorButtonIconLoudDefault": globals["colorScales"]["white"],
    "colorButtonIconLoudDisabled": globals["colorScales"]["white"],
    "colorButtonIconNeutralDefault": globals["colorScales"]["grey"]["600"],
    "colorButtonIconNeutralHover": globals["colorScales"]["grey"]["600"],
    "colorButtonIconNeutralDisabled": globals["colorScales"]["grey"]["400"],
    "colorButtonTextPrimaryDefault": globals["colorScales"]["white"],
    "colorButtonTextPrimaryDisabled": globals["colorScales"]["white"],
    "colorButtonTextSecondaryDefault": globals["colorScales"]["grey"]["500"],
    "colorButtonTextSecondaryDisabled": globals["colorScales"]["white"],
    "colorButtonTextTertiaryDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonTextTertiaryDisabled": globals["colorScales"]["white"],
    "colorButtonTextDestructiveDefault": globals["colorScales"]["white"],
    "colorButtonTextDestructiveDisabled": globals["colorScales"]["white"],
    "colorButtonTextQuietDefault": globals["colorScales"]["blue"]["500"],
    "colorButtonTextQuietHover": globals["colorScales"]["blue"]["500"],
    "colorButtonTextQuietDisabled": globals["colorScales"]["grey"]["300"],
    "colorButtonTextLoudDefault": globals["colorScales"]["white"],
    "colorButtonTextLoudDisabled": globals["colorScales"]["white"],
    "colorButtonTextNeutralDefault": globals["colorScales"]["grey"]["800"],
    "colorButtonTextNeutralHover": globals["colorScales"]["grey"]["800"],
    "colorButtonTextNeutralDisabled": globals["colorScales"]["grey"]["400"],
    "colorButtonBorderSecondaryDefault": globals["colorScales"]["grey"]["400"],
    "colorButtonBorderSecondaryDisabled": globals["colorScales"]["grey"]["200"],
    "colorButtonBorderTertiaryDefault": globals["colorScales"]["blue"]["400"],
    "colorButtonBorderTertiaryDisabled": globals["colorScales"]["grey"]["200"],
    "colorDialogBackgroundPrimary": globals["colorScales"]["white"],
    "colorProgressBackgroundPrimary": globals["colorScales"]["blue"]["500"],
    "colorProgressBackgroundSecondary": globals["colorScales"]["blue"]["200"],
    "colorProgressTextInfo": globals["colorScales"]["blue"]["400"],
    "colorProgressBarForegroundLinear": globals["colorScales"]["blue"]["500"],
    "colorProgressBarForegroundCircular": globals["colorScales"]["blue"]["500"],
    "colorProgressBarBackgroundLinear": globals["colorScales"]["blue"]["200"],
    "colorProgressBarTextPrimary": globals["colorScales"]["grey"]["700"],
    "colorNavigationButtonBackgroundPrimaryDefault": globals["colorScales"]["navy"]["800"],
    "colorNavigationButtonBackgroundPrimaryHover": globals["colorScales"]["blue"]["500"],
    "colorNavigationButtonBackgroundPrimaryPressed": globals["colorScales"]["blue"]["500"],
    "colorNavigationButtonTextPrimary": globals["colorScales"]["white"],
    "colorNavigationButtonIconPrimary": globals["colorScales"]["white"],
    "colorNavigationBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "colorNavigationBackgroundSecondary": globals["colorScales"]["navy"]["100"],
    "colorNavigationLinkBackgroundPrimaryHover": globals["colorScales"]["blue"]["500"],
    "colorNavigationLinkBackgroundPrimaryPressed": globals["colorScales"]["blue"]["600"],
    "colorNavigationLinkBackgroundPrimaryActive": globals["colorScales"]["blue"]["500"],
    "colorNavigationLinkTextPrimary": globals["colorScales"]["white"],
    "colorNavigationLinkIconPrimary": globals["colorScales"]["white"],
    "colorPaginationBackgroundDefault": globals["colorScales"]["white"],
    "colorPaginationBackgroundHover": globals["colorScales"]["grey"]["400"],
    "colorPaginationBackgroundActive": globals["colorScales"]["blue"]["500"],
    "colorPaginationBackgroundDisabled": globals["colorScales"]["white"],
    "colorPaginationTextDefault": globals["colorScales"]["grey"]["800"],
    "colorPaginationTextHover": globals["colorScales"]["white"],
    "colorPaginationTextActive": globals["colorScales"]["white"],
    "colorPaginationTextDisabled": globals["colorScales"]["grey"]["400"],
    "colorPaginationIconDefault": globals["colorScales"]["grey"]["800"],
    "colorPaginationIconHover": globals["colorScales"]["white"],
    "colorPaginationIconActive": globals["colorScales"]["white"],
    "colorPaginationIconDisabled": globals["colorScales"]["grey"]["400"],
    "colorToggleIconDefault": globals["colorScales"]["navy"]["700"],
    "colorToggleIconActive": globals["colorScales"]["blue"]["500"],
    "colorToggleIconDisabled": globals["colorScales"]["grey"]["300"],
    "colorToggleBackgroundOn": globals["colorScales"]["blue"]["300"],
    "colorToggleBackgroundOff": globals["colorScales"]["navy"]["300"],
    "colorToggleBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorToggleForegroundOn": globals["colorScales"]["blue"]["500"],
    "colorToggleForegroundOff": globals["colorScales"]["navy"]["100"],
    "colorToggleForegroundDisabled": globals["colorScales"]["grey"]["300"],
    "colorToggleOverlayOn": "rgba(135, 191, 236, 0.16)",
    "colorToggleOverlayOff": "rgba(169, 187, 203, 0.16)",
    "colorTextPrimary": globals["colorScales"]["grey"]["800"],
    "colorTextMuted": globals["colorScales"]["grey"]["600"],
    "colorTextSubtle": globals["colorScales"]["grey"]["500"],
    "colorTextDisabled": globals["colorScales"]["grey"]["300"],
    "colorTextInverse": globals["colorScales"]["white"],
    "colorTextSelected": globals["colorScales"]["blue"]["500"],
    "colorTextInfo": globals["colorScales"]["blue"]["500"],
    "colorTextSuccess": globals["colorScales"]["green"]["500"],
    "colorTextWarning": globals["colorScales"]["yellow"]["400"],
    "colorTextDanger": globals["colorScales"]["red"]["500"],
    "colorTextFeature": globals["colorScales"]["purple"]["500"],
    "colorTextLinkDefault": globals["colorScales"]["blue"]["500"],
    "colorTextLinkPressed": globals["colorScales"]["blue"]["600"],
    "colorIconPrimary": globals["colorScales"]["grey"]["800"],
    "colorIconMuted": globals["colorScales"]["grey"]["600"],
    "colorIconSubtle": globals["colorScales"]["grey"]["500"],
    "colorIconDisabled": globals["colorScales"]["grey"]["300"],
    "colorIconInverse": globals["colorScales"]["white"],
    "colorIconSelected": globals["colorScales"]["blue"]["500"],
    "colorIconInfo": globals["colorScales"]["blue"]["500"],
    "colorIconSuccess": globals["colorScales"]["green"]["500"],
    "colorIconWarning": globals["colorScales"]["yellow"]["400"],
    "colorIconDanger": globals["colorScales"]["red"]["500"],
    "colorIconFeature": globals["colorScales"]["purple"]["500"],
    "colorIconLinkDefault": globals["colorScales"]["blue"]["500"],
    "colorIconLinkPressed": globals["colorScales"]["blue"]["600"],
    "colorBorderPrimary": globals["colorScales"]["grey"]["200"],
    "colorBorderMuted": globals["colorScales"]["grey"]["100"],
    "colorBorderSubtle": globals["colorScales"]["grey"]["300"],
    "colorBorderDisabled": globals["colorScales"]["grey"]["300"],
    "colorBorderInverse": globals["colorScales"]["white"],
    "colorBorderSelected": globals["colorScales"]["blue"]["500"],
    "colorBorderInfo": globals["colorScales"]["blue"]["500"],
    "colorBorderSuccess": globals["colorScales"]["green"]["500"],
    "colorBorderWarning": globals["colorScales"]["yellow"]["400"],
    "colorBorderDanger": globals["colorScales"]["red"]["500"],
    "colorBorderFeature": globals["colorScales"]["purple"]["500"],
    "colorBorderInput": globals["colorScales"]["grey"]["300"],
    "colorBorderLinkDefault": globals["colorScales"]["blue"]["500"],
    "colorBorderLinkPressed": globals["colorScales"]["blue"]["600"],
    "colorBackgroundPrimaryDefault": globals["colorScales"]["white"],
    "colorBackgroundPrimaryHovered": globals["colorScales"]["grey"]["100"],
    "colorBackgroundPrimaryPressed": globals["colorScales"]["grey"]["200"],
    "colorBackgroundSecondaryDefault": globals["colorScales"]["grey"]["100"],
    "colorBackgroundSecondaryHovered": globals["colorScales"]["white"],
    "colorBackgroundSecondaryPressed": globals["colorScales"]["grey"]["300"],
    "colorBackgroundSubtle": globals["colorScales"]["grey"]["200"],
    "colorBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorBackgroundContrast": globals["colorScales"]["white"],
    "colorBackgroundInversePrimary": globals["colorScales"]["navy"]["900"],
    "colorBackgroundInverseSecondary": globals["colorScales"]["navy"]["700"],
    "colorBackgroundInverseNeutral": globals["colorScales"]["slate"]["500"],
    "colorBackgroundInverseSuccess": globals["colorScales"]["green"]["500"],
    "colorBackgroundSelected": globals["colorScales"]["blue"]["500"],
    "colorBackgroundInfo": globals["colorScales"]["blue"]["200"],
    "colorBackgroundSuccess": globals["colorScales"]["green"]["200"],
    "colorBackgroundWarning": globals["colorScales"]["yellow"]["100"],
    "colorBackgroundDanger": globals["colorScales"]["red"]["200"],
    "colorBackgroundFeature": globals["colorScales"]["purple"]["200"],
    "colorOverlayBackgroundPrimary": "rgba(17, 26, 35, 0.56)",
    "colorOverlayBackgroundMuted": "rgba(17, 26, 35, 0.24)",
    "colorActionButtonBorderSecondary": globals["colorScales"]["blue"]["400"],
    "colorActionButtonBorderDisabled": globals["colorScales"]["grey"]["300"],
    "colorActionButtonTextPrimary": globals["colorScales"]["white"],
    "colorActionButtonTextSecondary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonTextTernary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonTextDisabled": globals["colorScales"]["grey"]["400"],
    "colorActionButtonIconPrimary": globals["colorScales"]["white"],
    "colorActionButtonIconSecondary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonIconTernary": globals["colorScales"]["blue"]["500"],
    "colorActionButtonIconDisabled": globals["colorScales"]["grey"]["400"],
    "colorActionButtonBackgroundDisabled": globals["colorScales"]["grey"]["200"],
    "colorActionButtonBackgroundPrimaryDefault": globals["colorScales"]["green"]["500"],
    "colorActionButtonBackgroundPrimaryHover": globals["colorScales"]["green"]["600"],
    "colorActionButtonBackgroundPrimaryPressed": globals["colorScales"]["green"]["700"],
    "colorActionButtonBackgroundSecondaryDefault": globals["colorScales"]["blue"]["100"],
    "colorActionButtonBackgroundSecondaryHover": "rgba(26, 119, 202, 0.08)",
    "colorActionButtonBackgroundSecondaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorActionButtonBackgroundTernaryDefault": "rgba(26, 119, 202, 0.0)",
    "colorActionButtonBackgroundTernaryHover": "rgba(26, 119, 202, 0.08)",
    "colorActionButtonBackgroundTernaryPressed": "rgba(26, 119, 202, 0.24)",
    "colorActionButtonBackgroundCreateReleaseDefault": globals["colorScales"]["navy"]["900"],
    "colorActionButtonBackgroundCreateReleaseHover": globals["colorScales"]["navy"]["700"],
    "colorActionButtonBackgroundCreateReleasePressed": globals["colorScales"]["navy"]["600"],
    "colorCardBackgroundDefault": globals["colorScales"]["white"],
    "colorCardBackgroundHover": globals["colorScales"]["grey"]["100"],
    "colorCarouselTextPrimary": globals["colorScales"]["white"],
    "colorCarouselTextMuted": globals["colorScales"]["navy"]["200"],
    "colorCarouselIconDefault": "rgba(169, 187, 203, 0.32)",
    "colorCarouselIconHover": "rgba(169, 187, 203, 0.72)",
    "colorCarouselIconSelected": globals["colorScales"]["blue"]["400"],
    "colorCarouselIconActive": "rgba(169, 187, 203, 0.72)",
    "colorCarouselBackgroundPrimary": globals["colorScales"]["navy"]["900"],
    "shadowExtraSmall": "0 1px 8px 0 rgba(0,0,0,0.02), 0 1px 2px 0 rgba(0,0,0,0.06)",
    "shadowSmall": "0 1px 1px 0 rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.06)",
    "shadowMedium": "0 6px 12px 0 rgba(0,0,0,0.10), 0 1px 4px 0 rgba(0,0,0,0.04)",
    "shadowLarge": "0 14px 32px -4px rgba(0,0,0,0.12), 0 2px 6px 0 rgba(0,0,0,0.10)",
    "shadowFocusDefault": "0 0 0 3px #90CEFF"
};
