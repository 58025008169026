import type { RouteTemplate } from "./RouteTemplate";
export interface PartialRouteDefinition<RouteParameters> {
    template: RouteTemplate<RouteParameters>;
}
export type ParametersOfPartialRouteDefinition<T extends UnknownPartialRouteDefinition> = T extends PartialRouteDefinition<infer Parameters> ? Parameters : never;
// unknown doesn't work here when using this as a property on a PageRouteDefinition
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownPartialRouteDefinition = PartialRouteDefinition<any>;
export function createPartialRoute<RouteParameters>(template: RouteTemplate<RouteParameters>): PartialRouteDefinition<RouteParameters> {
    return {
        template,
    };
}
